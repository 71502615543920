import { createReducer, on } from '@ngrx/store';
import { feedbackInitialState } from './feedback.state';
import feedbackActions from './feedback.action';

export const feedbackReducer = createReducer(
  feedbackInitialState,
  on(feedbackActions.updateFilter, function (state, { data: filter }) {
    return { ...state, filter };
  }),
  on(feedbackActions.updatePagination, function (state, { data: pagination }) {
    return { ...state, pagination };
  }),
  on(
    feedbackActions.fetchFeedbackSuccess,
    function (state, { data: { count, data } }) {
      return { ...state, data, total: count };
    }
  )
);

import { Injectable, inject } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import { BaseEffect } from '@utils';
import departmentActions from './department.action';
import { catchError, exhaustMap, of, switchMap, withLatestFrom } from 'rxjs';
import { selectOrganization } from '@stores/auth/auth.selector';
import { DepartmentService } from '@services/department.service';
import { setErrorToast } from '@stores/shared/shared.actions';
import { MatDialogRefStore } from '@services/matDialogRefStore.service';
import { take } from 'rxjs';

@Injectable()
export class DepartmentEffect extends BaseEffect {
  private service = inject(DepartmentService);
  private matDialogRefStore = inject(MatDialogRefStore);

  fetchDepartment$ = createEffect(() => {
    return this.action$.pipe(
      ofType(departmentActions.fetch),
      withLatestFrom(this.store.select(selectOrganization)),
      exhaustMap(([_, organization]) =>
        this.service.get({ organizationId: organization.id }).pipe(
          switchMap(data => {
            return of(departmentActions.fetchSuccess({ data }));
          }),
          catchError(() => {
            return of(
              setErrorToast({ message: 'Unable to fetch department try Again' })
            );
          })
        )
      )
    );
  });
  update$ = createEffect(() => {
    return this.action$.pipe(
      ofType(departmentActions.update),
      withLatestFrom(this.store.select(selectOrganization)),
      exhaustMap(([{ data, id, message, ref }, organization]) =>
        this.service
          .update(id, { ...data, organizationId: organization.id })
          .pipe(
            switchMap(department => {
              ref && this.matDialogRefStore.close(ref);
              this.toastrService.success(
                message ?? 'Department updated successfully.'
              );
              return of(
                departmentActions.updateSuccess({
                  data: { changes: department, id },
                }),
                departmentActions.updateLoader({ isLoading: false }),
                departmentActions.fetch()
              );
            }),
            catchError(() =>
              of(
                setErrorToast({
                  message: 'Unable to update department, try again',
                }),
                departmentActions.updateLoader({ isLoading: false })
              )
            )
          )
      )
    );
  });

  delete$ = createEffect(() => {
    return this.action$.pipe(
      ofType(departmentActions.delete),
      exhaustMap(({ id }) => {
        return this.service.delete(id).pipe(
          switchMap(() => {
            this.toastrService.success('Department deleted successfully.');
            return of(
              departmentActions.deleteSuccess({ id }),
              departmentActions.fetchDissociateUser()
            );
          }),
          catchError(() =>
            of(
              setErrorToast({
                message: 'Unable to delete department.',
              })
            )
          )
        );
      })
    );
  });
  add$ = createEffect(() => {
    return this.action$.pipe(
      ofType(departmentActions.add),
      exhaustMap(({ data, ref }) =>
        this.store.select(selectOrganization).pipe(
          take(1),
          exhaustMap(organization =>
            this.service.add({ ...data, organizationId: organization.id }).pipe(
              switchMap(data => {
                ref && this.matDialogRefStore.close(ref);
                // Show a success toast with the provided message, or a default message
                this.toastrService.success('Department added successfully.');

                return of(
                  departmentActions.addSuccess({ data }),
                  departmentActions.updateLoader({ isLoading: false })
                );
              }),
              catchError(({ error }) =>
                of(
                  setErrorToast({
                    message:
                      error?.message ?? 'Unable to add Department try again.',
                  }),
                  departmentActions.updateLoader({ isLoading: false })
                )
              )
            )
          )
        )
      )
    );
  });
  addMember$ = createEffect(() => {
    return this.action$.pipe(
      ofType(departmentActions.addMember),
      exhaustMap(({ data }) =>
        this.service.addMember(data.id, data.userId).pipe(
          switchMap(member => {
            this.toastrService.success('Member added successfully.');
            return of(departmentActions.addMemberSuccess({ data: member }));
          }),
          catchError(() =>
            of(
              setErrorToast({
                message: 'Unable to add member try again.',
              })
            )
          )
        )
      )
    );
  });
  deleteMember$ = createEffect(() => {
    return this.action$.pipe(
      ofType(departmentActions.deleteMember),
      exhaustMap(({ departmentId, id, user }) =>
        this.service.deleteMember(id).pipe(
          switchMap(() => {
            this.toastrService.success('Member remove successfully.');
            return of(
              departmentActions.deleteMemberSuccess({ id, departmentId, user })
            );
          }),
          catchError(() =>
            of(setErrorToast({ message: 'Unable to remove member try again.' }))
          )
        )
      )
    );
  });

  fetchDissociateUser$ = createEffect(() => {
    return this.action$.pipe(
      ofType(departmentActions.fetchDissociateUser),
      withLatestFrom(this.store.select(selectOrganization)),
      exhaustMap(([_, organization]) =>
        this.service.dissociateMember(organization.id).pipe(
          switchMap(members =>
            of(departmentActions.fetchDissociateUserSuccess({ data: members }))
          ),
          catchError(() =>
            of(
              setErrorToast({
                message: 'Unable to fetch department member try again.',
              })
            )
          )
        )
      )
    );
  });
}

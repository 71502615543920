import { createReducer, on } from '@ngrx/store';
import { departmentAdapter, departmentInitialState } from './department.state';
import departmentActions from './department.action';
import { produce } from 'immer';

const departmentReducer = createReducer(
  departmentInitialState,
  on(departmentActions.addSuccess, (state, { data }) =>
    departmentAdapter.addOne(
      { ...data, members: [] },
      { ...state, isLoading: false }
    )
  ),
  on(departmentActions.updateSuccess, (state, { data }) =>
    departmentAdapter.updateOne(data, { ...state, isLoading: false })
  ),
  on(departmentActions.fetchSuccess, (state, { data }) =>
    departmentAdapter.setAll(data.data, state)
  ),
  on(departmentActions.deleteSuccess, (state, { id }) =>
    departmentAdapter.removeOne(id, state)
  ),

  on(departmentActions.updateLoader, function (state, { isLoading }) {
    return { ...state, isLoading };
  }),

  on(departmentActions.addMemberSuccess, function (state, { data }) {
    return produce(state, state => {
      const index = state.dissociateUser.findIndex(v => v.id === data.userId);
      if (index !== -1) {
        state.entities[data.departmentId]?.members.unshift({
          ...data,
          user: state.dissociateUser[index],
        });
        state.dissociateUser.splice(index, 1);
      }
      return state;
    });
  }),
  on(
    departmentActions.deleteMemberSuccess,
    function (state, { id, departmentId, user }) {
      return produce(state, draft => {
        const department = draft.entities[departmentId];

        if (department)
          department.members = department.members.filter(v => v.id !== id);
        user && draft.dissociateUser.unshift(user);
      });
    }
  ),
  on(departmentActions.fetchDissociateUserSuccess, function (state, { data }) {
    return { ...state, dissociateUser: data };
  })
);
export default departmentReducer;

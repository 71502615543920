import { paginationConfig } from '@constants';
import { Complaint, Pagination, ComplaintPageModel } from '@models';

export interface ComplaintState {
  data: Complaint[];
  total: number;
  pagination: Pagination;
  filter: ComplaintPageModel.filter;
}

export const complaintInitialState: ComplaintState = {
  data: [],
  total: 0,
  pagination: {
    offset: paginationConfig.defaultSize,
    page: 1,
  },
  filter: {
    status: 'all',
  },
};

import { createReducer, on } from '@ngrx/store';
import { initialState, organizationAdapter } from './organization.state';
import {
  addOrganizationSuccess,
  handleFailed,
  loadOrganizationSuccess,
  updateLoading,
  updateOrganizationSuccess,
} from './organization.action';

export const organizationReducer = createReducer(
  initialState,
  on(addOrganizationSuccess, (state, { data }) =>
    organizationAdapter.addOne(data, { ...state, isLoading: false })
  ),
  on(updateOrganizationSuccess, (state, { data }) =>
    organizationAdapter.updateOne(data, { ...state, isLoading: false })
  ),
  on(loadOrganizationSuccess, (state, { data }) =>
    organizationAdapter.setAll(data, state)
  ),
  on(handleFailed, function (state) {
    return { ...state, isLoading: false };
  }),
  on(updateLoading, function (state, { isLoading }) {
    return { ...state, isLoading };
  })
);

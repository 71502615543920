import { AgentPageModel } from '@models/pages';
export interface AgentState {
  total: number;
  data: AgentPageModel.AgentDetails[];
  requestInfo: AgentPageModel.RequestInfo;
  filter: AgentPageModel.filter;
}
export const agentInitialState: AgentState = {
  total: 0,
  filter: {},
  requestInfo: {
    totalRequest: 0,
    rejectedRequest: 0,
    acceptedRequest: 0,
    expiredRequest: 0,
  },
  data: [],
};

import { createReducer, on } from '@ngrx/store';
import { initialState, campaignAdapter } from './campaign.state';
import { campaignActions } from './campaign.action';
import { Campaign } from '@models';

export const campaignReducer = createReducer(
  initialState,
  on(campaignActions.createSuccess, (state, { campaign }) => {
    const currentList = Object.values(state.entities);

    return campaignAdapter.setAll(
      [campaign, ...(currentList as Campaign[])],
      state
    );
  }),
  on(campaignActions.updateSuccess, (state, { data }) =>
    campaignAdapter.updateOne(data, { ...state, isLoading: false })
  ),
  on(campaignActions.fetchSuccess, (state, { campaigns }) =>
    campaignAdapter.setAll(campaigns, state)
  ),
  on(campaignActions.deleteSuccess, (state, { id }) =>
    campaignAdapter.removeOne(id, state)
  ),
  on(campaignActions.updateLoading, function (state, { isLoading }) {
    return { ...state, isLoading };
  })
);

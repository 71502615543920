<main class="main-container" #mainComponent>
  <div class="container">
    <h1>
      4
      <div class="lock">
        <div class="top"></div>
        <div class="bottom"></div>
      </div>
      3
    </h1>
    <p>Access denied</p>
    <button class="mt-5" [routerLink]="'/'">
      <a
        class="group relative inline-block text-sm font-medium text-[#FF6A3D] focus:outline-none focus:ring active:text-orange-500">
        <span
          class="absolute inset-0 translate-x-0.5 translate-y-0.5 bg-secondary transition-transform group-hover:translate-x-0 group-hover:translate-y-0"></span>

        <span
          class="relative block border border-current bg-secondary px-8 py-3">
          <a>Go Home</a>
        </span>
      </a>
    </button>
  </div>
</main>

import { Injectable, inject } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import { VisitorService } from '@services/visitor.service';
import { BaseEffect } from '@utils/BaseEffect';
import VisitorActions from './visitor.action';
import {
  EMPTY,
  catchError,
  combineLatest,
  exhaustMap,
  of,
  switchMap,
  withLatestFrom,
  take,
} from 'rxjs';
import { selectOrganization } from '@stores/auth/auth.selector';
import { Pagination, VisitorPageModel } from '@models';
import { setErrorToast } from '@stores/shared/shared.actions';
import visitorSelector from './visitor.selector';
import visitorActions from './visitor.action';
import dayjs from 'dayjs';
import { environment } from '@environments';

@Injectable()
export class VisitorEffect extends BaseEffect {
  private service = inject(VisitorService);

  fetchVisitor$ = createEffect(() => {
    return this.action$.pipe(
      ofType(VisitorActions.fetchVisitor),
      withLatestFrom(
        combineLatest([
          this.store.select(selectOrganization),
          this.store.select(visitorSelector.selectPaginationAndFilter),
        ])
      ),
      exhaustMap(([_, [organization, { filter, pagination }]]) => {
        return this.service
          .get({
            filter: this.convertToFilter(filter, pagination),
            organizationId: organization.id,
          })
          .pipe(
            exhaustMap(data => {
              return of(VisitorActions.fetchVisitorSuccess({ data }));
            }),
            catchError(() => {
              return of(
                setErrorToast({ message: 'Unable to fetch visitor try again.' })
              );
            })
          );
      })
    );
  });

  update$ = createEffect(() => {
    return this.action$.pipe(
      ofType(visitorActions.updateVisitor),
      exhaustMap(({ clientDetails, id }) =>
        this.service.updateClientDetailsById(clientDetails, id).pipe(
          exhaustMap(visitor => {
            this.toastrService.success('Visitor updated successfully.');
            return of(visitorActions.updateVisitorSuccess({ data: visitor }));
          }),
          catchError(() => {
            return of(
              visitorActions.handleFailed({
                error: {
                  message: 'unable to edit user',
                },
              })
            );
          })
        )
      )
    );
  });

  handleFailed$ = createEffect(() => {
    return this.action$.pipe(
      ofType(visitorActions.handleFailed),
      exhaustMap(({ error }) => of(setErrorToast(error)))
    );
  });

  getAnalytics$ = createEffect(() => {
    return this.action$.pipe(
      ofType(visitorActions.fetchAnalytics),
      withLatestFrom(combineLatest([this.store.select(selectOrganization)])),
      exhaustMap(([{ id }, [{ id: organizationId }]]) => {
        return this.store
          .select(visitorSelector.selectAnalyticsByVisitorId(id))
          .pipe(
            take(1),
            exhaustMap(data => {
              if (
                data &&
                dayjs(data.createdAt)
                  .add(environment.cacheTime, 's')
                  .isAfter(dayjs(new Date())) // Data is still valid
              ) {
                return EMPTY;
              }

              this.store.dispatch(visitorActions.fetchAnalyticsLoading());

              return this.service
                .getAnalytics({
                  visitorIds: id,
                  organizationId,
                })
                .pipe(
                  switchMap(analytic =>
                    of(
                      visitorActions.fetchAnalyticsSuccess({
                        analytic: analytic[0],
                        id,
                      })
                    )
                  ),
                  catchError(err =>
                    of(
                      visitorActions.fetchAnalyticsFailure({
                        error: err.err?.message ?? 'Internal Server Error',
                      })
                    )
                  )
                );
            })
          );
      })
    );
  });

  private convertToFilter(
    filter: VisitorPageModel.filter,
    pagination?: Pagination
  ) {
    const where: Record<string, unknown> = {};
    if (filter.source) {
      where['source'] = { inq: filter.source };
    }

    if (filter.dateRange) {
      where['createdDate'] = this.getDateFilter(filter.dateRange);
    }
    if (filter.visitorId) {
      where['user_id'] = filter.visitorId;
      delete where['createdDate'];
    }
    if (filter.visitor) {
      where['or'] = [
        {
          'clientDetails.name': {
            like: `.*${filter.visitor}.*`,
            options: 'i',
          },
        },
        {
          'clientDetails.first_name': {
            like: `.*${filter.visitor}.*`,
            options: 'i',
          },
        },
        {
          'clientDetails.last_name': {
            like: `.*${filter.visitor}.*`,
            options: 'i',
          },
        },
        {
          user_id: filter.visitor,
        },
      ];
    }
    const condition: Record<string, unknown> = { where };
    if (filter.order) {
      condition['order'] =
        `${filter.order.field} ${filter.order.direction.toUpperCase()}`;
    } else {
      condition['order'] = `createdDate DESC`;
      condition['order'] = `updatedDate DESC`;
    }

    if (pagination) {
      condition['page'] = pagination.page;
      condition['limit'] = pagination.offset;
    }

    return condition;
  }
}

import { Injectable } from '@angular/core';
import { ApiResponse, likeDislikeQuery, Organization } from '@models';
import { BaseService } from '@utils';
import {} from '@models';

@Injectable({
  providedIn: 'root',
})
export class LikeDislikeService extends BaseService {
  get(filter: Record<string, unknown>) {
    return this.httpClient.get<ApiResponse<likeDislikeQuery[]>>(
      'likeDislikeQueries',
      {
        params: this.convertToHttpParams(filter),
      }
    );
  }
  archive(id: likeDislikeQuery['id'], organizationId: Organization['id']) {
    return this.httpClient.patch(
      `likeDislikeQueries/${organizationId}/archive/${id}`,
      {}
    );
  }
}

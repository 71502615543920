import { createReducer, on } from '@ngrx/store';
import { leadInitialState } from './lead.state';
import leadActions from './lead.action';

export const leadReducer = createReducer(
  leadInitialState,
  on(leadActions.updateFilter, function (state, { data: filter }) {
    return { ...state, filter };
  }),
  on(leadActions.updatePagination, function (state, { data: pagination }) {
    return { ...state, pagination };
  }),
  on(leadActions.fetchLeadSuccess, function (state, { data: { count, data } }) {
    return { ...state, data, total: count };
  }),
  on(leadActions.fetchInterestSuccess, function (state, { data }) {
    return { ...state, interest: data.map(v => ({ label: v, value: v })) };
  })
);

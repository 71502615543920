import { Injectable, inject } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import { AppointmentService, MatDialogRefStore } from '@services';
import { BaseEffect } from '@utils/BaseEffect';
import appointmentActions from './appointment.action';
import {
  catchError,
  combineLatest,
  exhaustMap,
  of,
  withLatestFrom,
} from 'rxjs';
import { selectOrganization } from '@stores/auth/auth.selector';
import appointmentSelector from './appointment.selector';
import { Pagination, AppointmentPageModel, Organization } from '@models';
import { setErrorToast } from '@stores/shared/shared.actions';

@Injectable()
export class AppointmentEffect extends BaseEffect {
  private service = inject(AppointmentService);
  private matDialogRefStore = inject(MatDialogRefStore);

  fetchAppointment$ = createEffect(() => {
    return this.action$.pipe(
      ofType(appointmentActions.fetchAppointment),
      withLatestFrom(
        combineLatest([
          this.store.select(selectOrganization),
          this.store.select(appointmentSelector.selectPaginationAndFilter),
        ])
      ),
      exhaustMap(([_, [organization, { filter, pagination }]]) => {
        return this.service
          .get({
            filter: {
              ...this.convertToFilter(
                { ...filter, organizationId: organization.id, archive: false },
                pagination
              ),
            },
          })
          .pipe(
            exhaustMap(data =>
              of(appointmentActions.fetchAppointmentSuccess({ data }))
            ),
            catchError(() =>
              of(
                setErrorToast({
                  message: 'Unable to fetch appointment try again.',
                })
              )
            )
          );
      })
    );
  });

  archive$ = createEffect(() => {
    return this.action$.pipe(
      ofType(appointmentActions.archiveAppointment),
      withLatestFrom(this.store.select(selectOrganization)),
      exhaustMap(([{ id }, organization]) =>
        this.service.archive(id, organization.id).pipe(
          exhaustMap(() => {
            this.toastrService.success('Appointment archive successfully');
            return of(appointmentActions.fetchAppointment());
          }),
          catchError(() =>
            of(setErrorToast({ message: 'Internal server error Try Again' }))
          )
        )
      )
    );
  });
  confirm$ = createEffect(() => {
    return this.action$.pipe(
      ofType(appointmentActions.confirmAppointments),
      withLatestFrom(this.store.select(selectOrganization)),
      exhaustMap(([{ id, date, ref }, organization]) =>
        this.service.confirm(id, organization.id, date).pipe(
          exhaustMap(() => {
            this.toastrService.success('Appointment Updated Successfully');
            if (ref) this.matDialogRefStore.close(ref);
            return of(appointmentActions.fetchAppointment());
          }),
          catchError(() =>
            of(setErrorToast({ message: 'Internal server error Try Again' }))
          )
        )
      )
    );
  });

  private convertToFilter(
    filter: AppointmentPageModel.filter & {
      archive?: boolean;
      organizationId?: Organization['id'];
    },
    pagination?: Pagination
  ) {
    const where: Record<string, unknown> = {};
    if (filter.organizationId) {
      where['organizationId'] = filter.organizationId;
    }
    if (filter.visitorId) {
      where['visitorId'] = filter.visitorId;
    }
    if (Object.hasOwn(filter, 'archive')) {
      where['archive'] = filter.archive;
    }
    if (filter.date) {
      where['createdDate'] = this.getDateFilter(filter.date);
    }

    const condition: Record<string, unknown> = { where };
    if (filter.order) {
      condition['order'] =
        `${filter.order.field} ${filter.order.direction.toUpperCase()}`;
    } else {
      condition['order'] = `createdDate DESC`;
    }
    if (pagination) {
      condition['page'] = pagination.page;
      condition['limit'] = pagination.offset;
    }
    return condition;
  }
}

// write a ngrx effect
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, switchMap } from 'rxjs/operators';
import { DashboardService } from '@services';
import { dashboardActions } from './dashboard.action';
import { of } from 'rxjs';
import { setErrorToast } from '@stores/shared/shared.actions';

@Injectable()
export class DashboardEffects {
  getQueriesCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dashboardActions.queryCount),
      exhaustMap(({ filter, organizationId }) =>
        this.dashboardService.getQueriesCount(organizationId, filter).pipe(
          switchMap(data => of(dashboardActions.queryCountSuccess({ data }))),
          catchError(() =>
            of(
              setErrorToast({
                message: 'Unable to load queries refresh the page.',
              })
            )
          )
        )
      )
    );
  });
  getVisitorCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dashboardActions.visitorCount),
      exhaustMap(({ filter }) => {
        const { date, ...remainingFilter } = filter;
        return this.dashboardService
          .getVisitorCount({
            ...(date as Record<string, unknown>),
            ...remainingFilter,
          })
          .pipe(
            switchMap(data =>
              of(dashboardActions.visitorCountSuccess({ data }))
            ),
            catchError(() =>
              of(
                setErrorToast({
                  message: 'Unable to load visitor count refresh the page.',
                })
              )
            )
          );
      })
    );
  });
  getBotCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dashboardActions.botCount),
      exhaustMap(({ filter }) => {
        const { date, ...remainingFilter } = filter;
        return this.dashboardService
          .getBotCount({
            ...(date as Record<string, unknown>),
            ...remainingFilter,
          })
          .pipe(
            switchMap(data => of(dashboardActions.botCountSuccess({ data }))),
            catchError(() =>
              of(
                setErrorToast({
                  message: 'Unable to load bot count refresh the page.',
                })
              )
            )
          );
      })
    );
  });
  getNewVisitorCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dashboardActions.newVisitorCount),
      exhaustMap(({ filter }) => {
        const { date, ...remainingFilter } = filter;
        return this.dashboardService
          .getNewVisitorCount({
            ...(date as Record<string, unknown>),
            ...remainingFilter,
          })
          .pipe(
            switchMap(data =>
              of(dashboardActions.newVisitorCountSuccess({ data }))
            ),
            catchError(() =>
              of(
                setErrorToast({
                  message: 'Unable to load bot count refresh the page.',
                })
              )
            )
          );
      })
    );
  });
  getLeadCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dashboardActions.leadsCount),
      exhaustMap(({ filter, orgId }) => {
        return this.dashboardService.getLeadsBySource(filter, orgId).pipe(
          exhaustMap(data => of(dashboardActions.leadsCountSuccess({ data }))),
          catchError(() => {
            return of(
              setErrorToast({
                message: 'Unable to fetch leads count',
              })
            );
          })
        );
      })
    );
  });

  getComplaintCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dashboardActions.complaintsCount),
      exhaustMap(({ duration, orgId }) => {
        return this.dashboardService.getComplaintsCount(duration, orgId).pipe(
          exhaustMap(data =>
            of(dashboardActions.complaintsCountSuccess({ data }))
          ),
          catchError(() => {
            return of(
              setErrorToast({
                message: 'Unable to fetch complaint data',
              })
            );
          })
        );
      })
    );
  });
  constructor(
    private actions$: Actions,
    private dashboardService: DashboardService
  ) {}
}

<app-loading />

@defer (when auth.isSettingFetched || !auth.token) {
  @if (showLayout) {
    @switch (layout) {
      @case ('auth') {
        <app-auth />
      }
      @default {
        <app-main />
      }
    }
  } @else {
    <router-outlet />
  }
} @placeholder {
  <div
    class="animate-duration-[2s] grid h-screen w-full animate-pulse grid-cols-12 grid-rows-12 items-center gap-2 p-2">
    <div
      class="col-span-2 row-span-full h-full w-full rounded-xl bg-[#67676730]"></div>
    <div
      class="col-span-10 row-span-1 h-full w-full rounded-xl bg-[#67676730]"></div>
    <div
      class="col-span-10 row-span-9 h-full w-full rounded-xl bg-[#67676730]"></div>
    <div
      class="col-span-10 row-span-2 h-full w-full rounded-xl bg-[#67676730]"></div>
  </div>
} @error {
  loading
}

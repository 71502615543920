import { Injectable, inject } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import { BaseEffect } from '@utils/BaseEffect';
import {
  catchError,
  combineLatest,
  exhaustMap,
  of,
  // tap,
  withLatestFrom,
} from 'rxjs';
import { selectOrganization } from '@stores/auth/auth.selector';
import { Pagination, Organization, likeDisLikePageModel } from '@models';
import { setErrorToast } from '@stores/shared/shared.actions';
import likeDislikeActions from './likeDislike.action';
import likeDislikeSelector from './likeDislike.selector';
import { LikeDislikeService } from '@services/likeDislikeQuery.service';

@Injectable()
export class LikeDislikeEffect extends BaseEffect {
  private service = inject(LikeDislikeService);

  fetchLikeDislikeQuery$ = createEffect(() => {
    return this.action$.pipe(
      ofType(
        likeDislikeActions.fetchLikeDislikeQuery,
        likeDislikeActions.updatePagination
      ),
      withLatestFrom(
        combineLatest([
          this.store.select(selectOrganization),
          this.store.select(likeDislikeSelector.selectPaginationAndFilter),
        ])
      ),
      exhaustMap(([_, [organization, { filter, pagination }]]) => {
        return this.service
          .get({
            filter: {
              ...this.convertToFilter(
                { ...filter, archive: false },
                pagination
              ),
            },
            organizationId: organization.id,
          })
          .pipe(
            exhaustMap(data =>
              of(likeDislikeActions.fetchLikeDislikeQuerySuccess({ data }))
            ),
            catchError(error => {
              console.error('Error fetching data: ', error);
              return of(
                setErrorToast({
                  message: 'Unable to fetch LikeDislike data, try again.',
                })
              );
            })
          );
      })
    );
  });
  archive$ = createEffect(() => {
    return this.action$.pipe(
      ofType(likeDislikeActions.archiveLikeDislike),
      withLatestFrom(this.store.select(selectOrganization)),
      exhaustMap(([{ id }, organization]) =>
        this.service.archive(id, organization.id).pipe(
          exhaustMap(() => {
            this.toastrService.success('QueryFeedback archived successfully');
            return of(likeDislikeActions.fetchLikeDislikeQuery());
          }),
          catchError(() =>
            of(setErrorToast({ message: 'Internal server error Try Again' }))
          )
        )
      )
    );
  });

  private convertToFilter(
    filter: likeDisLikePageModel.filter & {
      archive?: boolean;
      organizationId?: Organization['id'];
    },
    pagination?: Pagination
  ) {
    const where: Record<string, unknown> = {};
    if (filter.organizationId) {
      where['organizationId'] = filter.organizationId;
    }

    if (Object.hasOwn(filter, 'archive')) {
      where['archive'] = filter.archive;
    }
    if (filter.dateRange) {
      where['createdDate'] = this.getDateFilter(filter.dateRange);
    }
    if (filter.query) {
      const searchQuery = `.*${filter.query}.*`;
      if (!where['or']) {
        where['or'] = [];
      }
      (where['or'] as Array<any>).push(
        {
          visitorId: {
            like: searchQuery,
            options: 'i',
          },
        },
        {
          query: {
            like: searchQuery,
            options: 'i',
          },
        },
        {
          response: {
            like: searchQuery,
            options: 'i',
          },
        }
      );
    }

    const condition: Record<string, unknown> = { where };
    if (filter.order) {
      condition['order'] =
        `${filter.order.field} ${filter.order.direction.toUpperCase()}`;
    } else {
      condition['order'] = `createdDate DESC`;
    }
    if (pagination) {
      condition['page'] = pagination.page;
      condition['limit'] = pagination.offset;
    }
    return condition;
  }
}

import { createReducer, on } from '@ngrx/store';
import { agentInitialState } from './agent.state';
import agentActions from './agent.actions';

export const agentReducer = createReducer(
  agentInitialState,
  on(agentActions.updateFilter, function (state, { filter }) {
    return { ...state, filter };
  }),

  on(
    agentActions.fetchAgentsSuccess,
    function (state, { data: { count, data } }) {
      return {
        ...state,
        requestInfo: data.requestInfo,
        data: data.agentDetails,
        total: count,
      };
    }
  )
);

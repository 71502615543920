import { createReducer, on } from '@ngrx/store';
import { RESET_INITIAL_STATE } from './reset.state';
import resetActions from '../reset.action';

export const resetReducer = createReducer(
  RESET_INITIAL_STATE,
  on(resetActions.resetEmailSuccess, function () {
    return { resetMailSent: true };
  })
);

import { Injectable } from '@angular/core';
import { ApiResponse, Visitor, VisitorPageModel } from '@models';
import { BaseService } from '@utils';

@Injectable()
export class VisitorService extends BaseService {
  get(filter: Record<string, unknown>) {
    return this.httpClient.get<ApiResponse<Visitor[]>>('Visitors', {
      params: this.convertToHttpParams(filter),
    });
  }
  updateClientDetailsById(clientDetails: Visitor['clientDetails'], id: string) {
    return this.httpClient.patch<Visitor>(`Visitors/${id}`, {
      clientDetails,
    });
  }

  getAnalytics(filter: Record<string, unknown>) {
    return this.httpClient.get<VisitorPageModel.Analytics[]>(
      `Sessions/visitorAnalytics`,
      { params: this.convertToHttpParams(filter) }
    );
  }
}

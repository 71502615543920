import { createReducer, on } from '@ngrx/store';

import { apiLogAction } from './apiLogs.action';
import { apiLogInitialState } from './apiLogs.state';
import { groupBy } from 'lodash';
// todo implement server side if data is too larger or impact in performance
export const apiLogReducer = createReducer(
  apiLogInitialState,
  on(apiLogAction.fetchSuccess, (state, { data }) => {
    const stateData = Object.values(groupBy(data, v => v.description.code))
      .map(apiLogs => {
        const span = apiLogs.length;

        return apiLogs.map(v => ({
          fullName: v.description.fullname,
          code: v.description.code,
          branchName: v.description.branchname,
          span,
          ...v,
        }));
      })
      .flat();
    return { ...state, data: stateData };
  }),
  on(apiLogAction.fetchDropperSuccess, (state, { data }) => {
    return {
      ...state,
      dropper: {
        category: data.category.map(v => ({ label: v, value: v })),
        type: data.type.map(v => ({ label: v, value: v })),
      },
    };
  })
);

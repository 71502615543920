import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideStore } from '@ngrx/store';
import { provideRouterStore } from '@ngrx/router-store';
import { provideEffects } from '@ngrx/effects';
import { appEffect, appReducer } from '@stores/app.state';
import { CustomRouterStateSerializer } from '@stores/router/custom-serializer';
import { provideToastr } from 'ngx-toastr';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { customInterceptor } from './interceptor/custom.interceptor';
import { environment } from '@environments';
import { provideQuillConfig } from 'ngx-quill';
import { withHashLocation } from '@angular/router';
export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withHashLocation()),
    // provideRouter(routes),
    provideAnimations(),
    provideStore(appReducer),
    provideRouterStore({ serializer: CustomRouterStateSerializer }),
    provideEffects(appEffect),
    provideHttpClient(withInterceptors([customInterceptor])),
    provideToastr({
      timeOut: 3000,
      countDuplicates: true,
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true,
      newestOnTop: true,
      progressBar: true,
      progressAnimation: 'decreasing',
    }),
    provideQuillConfig({
      modules: {
        syntax: true,
      },
    }),
    environment.providers,
  ],
};

import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, exhaustMap, tap } from 'rxjs';
import {
  setErrorToast,
  showSnackbar,
  toggleSidebar,
  toggleTheme,
} from '@stores/shared/shared.actions';
import {
  selectIsDarkTheme,
  selectIsSidebarOpen,
} from '@stores/shared/shared.selector';
import { ToastrService } from 'ngx-toastr';
import { LocalStorage } from '@utils';
import { MatSnackBar } from '@angular/material/snack-bar';

import { SnackbarData } from '@models';

@Injectable()
export class SharedEffect {
  setErrorAlert$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(setErrorToast),
        tap(({ message, title, override }) => {
          this.toastrService.error(message, title, override);
        })
      );
    },
    { dispatch: false }
  );
  toggleSidebar$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(toggleSidebar),
        concatLatestFrom(() => this.store.select(selectIsSidebarOpen)),
        exhaustMap(([_action, isSidebarOpen]) => {
          this.localStorage.set('sidebar', isSidebarOpen);
          return EMPTY;
        })
      );
    },
    { dispatch: false }
  );
  toggleDarkTheme$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(toggleTheme),
        concatLatestFrom(() => this.store.select(selectIsDarkTheme)),
        exhaustMap(([_action, isDarkTheme]) => {
          document.body.classList.toggle('dark', !isDarkTheme);
          this.localStorage.set('darkMode', !isDarkTheme ? 'true' : 'false');
          return EMPTY;
        })
      );
    },
    { dispatch: false }
  );
  getSnackbarPanelClass = (type: SnackbarData['type']) => {
    const panelClasses: Record<typeof type, string> = {
      error: '!bg-red-500',
      info: '!bg-primary-500',
      success: '!bg-emerald-500',
      warning: '!bg-amber-500',
    };
    return panelClasses[type];
  };

  openSnackBar$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(showSnackbar),
        tap(({ duration, data }) => {
          this.snackBar.open(data.message, undefined, {
            duration: duration ?? 2 * 1000,
            horizontalPosition: data.position?.horizontal ?? 'start',
            panelClass: `snackbar-${data.type}`,
            verticalPosition: data.position?.vertical ?? 'bottom',
          });
        })
      );
    },
    {
      dispatch: false,
    }
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private localStorage: LocalStorage,
    private toastrService: ToastrService,
    private snackBar: MatSnackBar
  ) {}
}

import { Injectable, inject } from '@angular/core';
import { ComplaintPageModel, Organization, Pagination } from '@models';
import { createEffect, ofType } from '@ngrx/effects';
import { ComplaintService } from '@services/complaint.service';
import { selectOrganization } from '@stores/auth/auth.selector';
import { setErrorToast } from '@stores/shared/shared.actions';
import { BaseEffect } from '@utils/BaseEffect';
import dayjs from 'dayjs';
import {
  EMPTY,
  catchError,
  combineLatest,
  exhaustMap,
  of,
  withLatestFrom,
} from 'rxjs';
import complaintActions from './complaint.action';
import complaintSelector from './complaint.selector';

@Injectable()
export class ComplaintEffect extends BaseEffect {
  private service = inject(ComplaintService);

  fetchComplaint$ = createEffect(() => {
    return this.action$.pipe(
      ofType(complaintActions.fetchComplaint),
      withLatestFrom(
        combineLatest([
          this.store.select(selectOrganization),
          this.store.select(complaintSelector.selectPaginationAndFilter),
        ])
      ),
      exhaustMap(([_, [organization, { filter, pagination }]]) => {
        return this.service
          .get({
            filter: {
              ...this.convertToFilter(
                { ...filter, archive: false },
                pagination
              ),
              include: 'visitor',
            },
            organizationId: organization.id,
          })
          .pipe(
            exhaustMap(data =>
              of(complaintActions.fetchComplaintSuccess({ data }))
            ),
            catchError(() =>
              of(
                setErrorToast({
                  message: 'Unable to fetch complaint try again.',
                })
              )
            )
          );
      })
    );
  });

  archive$ = createEffect(() => {
    return this.action$.pipe(
      ofType(complaintActions.archiveComplaint),
      withLatestFrom(this.store.select(selectOrganization)),
      exhaustMap(([{ id }, organization]) =>
        this.service.archive(id, organization.id).pipe(
          exhaustMap(() => {
            this.toastrService.success('Complaint archive successfully');
            return of(complaintActions.fetchComplaint());
          }),
          catchError(() =>
            of(setErrorToast({ message: 'Internal server error Try Again' }))
          )
        )
      )
    );
  });

  resolve$ = createEffect(() => {
    return this.action$.pipe(
      ofType(complaintActions.resolveComplaints),

      exhaustMap(({ ids }) =>
        this.service.resolve({ ids: ids.join(','), status: true }).pipe(
          exhaustMap(() => {
            this.toastrService.success('Complaint resolve successfully');
            return of(complaintActions.fetchComplaint());
          }),
          catchError(() =>
            of(setErrorToast({ message: 'Internal server error Try Again' }))
          )
        )
      )
    );
  });

  exportComplaint$ = createEffect(() => {
    return this.action$.pipe(
      ofType(complaintActions.exportToCsv),
      withLatestFrom(
        combineLatest([
          this.store.select(selectOrganization),
          this.store.select(complaintSelector.selectFilter),
        ])
      ),
      exhaustMap(([_, [organization, filter]]) =>
        this.service
          .exportToCsv({
            filter: {
              ...this.convertToFilter({
                ...filter,
                archive: false,
                organizationId: organization.id,
              }),
            },
          })
          .pipe(
            exhaustMap(response => {
              if (window.confirm('Do you want to save complaints?')) {
                this.service.saveFile(
                  response,
                  `complaints-${dayjs().format('YYYY-MM-DD hh:mm:ss A')}.csv`
                );
              }
              return EMPTY;
            }),
            catchError(() =>
              of(
                setErrorToast({
                  message: 'Some thing error occurred try again!.',
                })
              )
            )
          )
      )
    );
  });

  private convertToFilter(
    filter: ComplaintPageModel.filter & {
      archive?: boolean;
      organizationId?: Organization['id'];
    },
    pagination?: Pagination
  ) {
    const where: Record<string, unknown> = {};
    if (filter.organizationId) {
      where['organizationId'] = filter.organizationId;
    }
    if (filter.status && filter.status !== 'all') {
      where['status'] = filter['status'] === 'complete';
    }
    if (Object.hasOwn(filter, 'archive')) {
      where['archive'] = filter.archive;
    }
    if (filter.dateRange) {
      where['createdDate'] = this.getDateFilter(filter.dateRange);
    }
    if (filter.complaintsId && filter.complaintsId.length > 0) {
      where['id'] = { inq: filter.complaintsId };
    }
    if (filter.query) {
      const searchQuery = `.*${filter.query}.*`;
      if (!where['or']) {
        where['or'] = [];
      }
      (where['or'] as Array<any>).push(
        {
          full_name: {
            like: searchQuery,
            options: 'i',
          },
        },
        {
          email_address: {
            like: searchQuery,
            options: 'i',
          },
        },
        {
          mobile_number: {
            like: searchQuery,
            options: 'i',
          },
        }
      );
    }

    const condition: Record<string, unknown> = { where };
    if (filter.order) {
      condition['order'] =
        `${filter.order.field} ${filter.order.direction.toUpperCase()}`;
    } else {
      condition['order'] = `createdDate DESC`;
    }
    if (pagination) {
      condition['page'] = pagination.page;
      condition['limit'] = pagination.offset;
    }
    if (filter.visitorId) {
      where['visitorId'] = filter.visitorId;
    }
    return condition;
  }
}

import { createReducer, on } from '@ngrx/store';
import { appointmentInitialState } from './appointment.state';
import appointmentActions from './appointment.action';

export const appointmentReducer = createReducer(
  appointmentInitialState,
  on(appointmentActions.updateFilter, function (state, { data: filter }) {
    return { ...state, filter };
  }),
  on(
    appointmentActions.updatePagination,
    function (state, { data: pagination }) {
      return { ...state, pagination };
    }
  ),
  on(
    appointmentActions.fetchAppointmentSuccess,
    function (state, { data: { count, data } }) {
      return { ...state, data, total: count };
    }
  )
);

import { Injectable, inject } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import { BaseEffect } from '@utils/BaseEffect';
import { apiLogAction } from './apiLogs.action';
import {
  EMPTY,
  catchError,
  combineLatest,
  exhaustMap,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import { selectOrganization } from '@stores/auth/auth.selector';
import apiLogsSelector from './apiLogs.selector';
import { ApiLogService } from '@services';
import { setErrorToast } from '@stores/shared/shared.actions';
import dayjs from 'dayjs';

@Injectable()
export class ApiLogsEffect extends BaseEffect {
  private service = inject(ApiLogService);
  fetch$ = createEffect(() => {
    return this.action$.pipe(
      ofType(apiLogAction.fetch),
      withLatestFrom(
        combineLatest([
          this.store.select(selectOrganization),
          this.store.select(apiLogsSelector.selectApiLogs),
        ])
      ),
      exhaustMap(([_, [organization, apiLogs]]) => {
        if (
          apiLogs.length > 0 &&
          apiLogs[0].organizationId === organization.id
        ) {
          return EMPTY;
        }
        return this.service
          .get({ organizationId: organization.id, isPagination: false })
          .pipe(switchMap(data => of(apiLogAction.fetchSuccess({ data }))));
      })
    );
  });
  dropper$ = createEffect(() => {
    return this.action$.pipe(
      ofType(apiLogAction.fetchDropper),
      withLatestFrom(this.store.select(selectOrganization)),
      exhaustMap(([_, organization]) =>
        this.service.getDropper(organization.id).pipe(
          switchMap(data => of(apiLogAction.fetchDropperSuccess({ data }))),
          catchError(() =>
            of(
              setErrorToast({
                message: 'unable to fetch dropper.Try again....',
              })
            )
          )
        )
      )
    );
  });
  exportData$ = createEffect(() => {
    return this.action$.pipe(
      ofType(apiLogAction.exportToCsv),
      withLatestFrom(combineLatest([this.store.select(selectOrganization)])),
      exhaustMap(([{ filter }, [organization]]) =>
        this.service.exportToCsv({ ...filter }, organization.id).pipe(
          exhaustMap(response => {
            if (window.confirm('Do you want to save api logs ?')) {
              this.service.saveFile(
                response,
                `api-logs-${dayjs().format('YYYY-MM-DD hh:mm:ss A')}.csv`
              );
            }
            return EMPTY;
          }),
          catchError(() =>
            of(
              setErrorToast({
                message: 'Some thing error occurred try again!.',
              })
            )
          )
        )
      )
    );
  });
}

import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-unauthorize',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './unauthorize.component.html',
  styleUrl: './unauthorize.component.scss',
})
export class UnauthorizeComponent implements AfterViewInit {
  interval = 500;
  @ViewChild('mainComponent') mainContainerRef: ElementRef;
  constructor(
    private renderer: Renderer2,
    private changeDetectionRef: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    setInterval(() => {
      this.generateLocks();
    }, this.interval);
    this.generateLocks();
  }
  generateLocks() {
    const lock = this.renderer.createElement('div');
    const position = this.generatePosition();
    const topElm = this.renderer.createElement('div');
    const bottomElm = this.renderer.createElement('div');
    this.renderer.addClass(topElm, 'top');
    this.renderer.addClass(bottomElm, 'bottom');

    this.renderer.appendChild(lock, topElm);
    this.renderer.appendChild(lock, bottomElm);
    this.renderer.addClass(lock, 'lock');
    this.renderer.setStyle(lock, 'top', position[0]);
    this.renderer.setStyle(lock, 'left', position[1]);
    setTimeout(() => {
      this.renderer.appendChild(this.mainContainerRef.nativeElement, lock);
      this.renderer.addClass(lock, 'generated');
    }, 100);
    // setTimeout(() => {
    //   this.renderer.removeChild(this.mainContainerRef.nativeElement, lock);
    // }, 3000);
  }

  generatePosition(): string[] {
    const x = Math.round(Math.random() * 100 - 10) + '%';
    const y = Math.round(Math.random() * 100) + '%';
    return [x, y];
  }
}

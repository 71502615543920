import { routerReducer } from '@ngrx/router-store';
import { SharedEffect } from './shared/shared.effect';
import { SharedState } from './shared/shared.state';
import { SHARED_STATE_NAME } from './shared/shared.selector';
import { SharedReducer } from './shared/shared.reducer';
import { Auth } from './auth/auth.state';
import { AUTH_STATE_NAME } from './auth/auth.selector';
import { AuthEffect } from './auth/auth.effect';
import { authReducer } from './auth/auth.reducer';
export interface AppState {
  [SHARED_STATE_NAME]: SharedState;
  [AUTH_STATE_NAME]: Auth;
}
export const appEffect = [SharedEffect, AuthEffect];
export const appReducer = {
  [SHARED_STATE_NAME]: SharedReducer,
  router: routerReducer,
  [AUTH_STATE_NAME]: authReducer,
};

import { Injectable, inject } from '@angular/core';
import { Pagination, TestDrivePageModel } from '@models';
import { createEffect, ofType } from '@ngrx/effects';
import { TestDriveService } from '@services/testdrive.service';
import { selectOrganization } from '@stores/auth/auth.selector';
import { setErrorToast } from '@stores/shared/shared.actions';
import { BaseEffect } from '@utils/BaseEffect';
import dayjs from 'dayjs';
import {
  EMPTY,
  catchError,
  combineLatest,
  exhaustMap,
  of,
  withLatestFrom,
} from 'rxjs';
import testdriveActions from './testDrive.actions';
import testdriveSelector from './testDrive.selector';

@Injectable()
export class TestDriveEffect extends BaseEffect {
  private service = inject(TestDriveService);

  fetchTestDrive$ = createEffect(() => {
    return this.action$.pipe(
      ofType(testdriveActions.fetchTestDrive),
      withLatestFrom(
        combineLatest([
          this.store.select(selectOrganization),
          this.store.select(testdriveSelector.selectPaginationAndFilter),
        ])
      ),
      exhaustMap(([_, [organization, { filter, pagination }]]) => {
        return this.service
          .get({
            filter: this.convertToFilter(filter, pagination),
            organizationId: organization.id,
          })
          .pipe(
            exhaustMap(registrations => {
              return of(
                testdriveActions.fetchTestdriveSuccess({ registrations })
              );
            }),
            catchError(() =>
              of(
                setErrorToast({
                  message: 'Unable to fetch testdrive try again.',
                })
              )
            )
          );
      })
    );
  });

  exportTestDrive$ = createEffect(() => {
    return this.action$.pipe(
      ofType(testdriveActions.exportToCsv),
      withLatestFrom(
        combineLatest([
          this.store.select(selectOrganization),
          this.store.select(testdriveSelector.selectFilter),
        ])
      ),
      exhaustMap(([_, [organization, filter]]) =>
        this.service
          .exportToCsv(organization.id, {
            filter: this.convertToFilter(filter),
          })
          .pipe(
            exhaustMap(response => {
              if (window.confirm('Do you want to save testdrive?')) {
                this.service.saveFile(
                  response,
                  `testdrive-${dayjs().format('YYYY-MM-DD hh:mm:ss A')}.csv`
                );
              }
              return EMPTY;
            }),
            catchError(() => {
              return of(
                setErrorToast({
                  message: 'Some thing error occurred try again!.',
                })
              );
            })
          )
      )
    );
  });

  addtohandleResolve$ = createEffect(() => {
    return this.action$.pipe(
      ofType(testdriveActions.handleResolve),
      exhaustMap(({ registrations }) =>
        this.service.resolveTestDrive(registrations.ids).pipe(
          exhaustMap(() => {
            this.toastrService.success('Test Drive Added successfully.');
            return of(
              testdriveActions.handleResolveSuccess({
                ids: registrations.ids,
              })
            );
          }),
          catchError(() =>
            of(setErrorToast({ message: 'Unable to add testdrive try again.' }))
          )
        )
      )
    );
  });

  private convertToFilter(
    filter: TestDrivePageModel.filter,
    pagination?: Pagination
  ) {
    const where: Record<string, unknown> = {};
    if (filter.type && filter.type !== 'all') {
      where['type'] = filter['type'];
    }
    if (filter.dateRange) {
      where['date'] = this.getDateFilter(filter.dateRange);
    }

    if (filter.testDriveIds && filter?.testDriveIds.length > 0) {
      where['id'] = { inq: filter.testDriveIds };
    }

    if (filter.query) {
      where['or'] = [
        ...((where['or'] as []) ?? []),
        {
          fullname: {
            like: `.*${filter.query}.*`,
            options: 'i',
          },
        },
        {
          email: `${filter.query}`,
        },
      ];
    }

    const condition: Record<string, unknown> = { where };
    if (filter.order) {
      condition['order'] =
        `${filter.order.field} ${filter.order.direction.toUpperCase()}`;
    } else {
      condition['order'] = `createdDate DESC`;
    }
    if (pagination) {
      condition['page'] = pagination.page;
      condition['limit'] = pagination.offset;
    }

    return condition;
  }
}

import { createReducer, on } from '@ngrx/store';
import { OfferInitialState, offerAdapter } from './offer.state';
import { offerActions } from './offer.action';

export const offerReducer = createReducer(
  OfferInitialState,
  on(offerActions.createOfferSuccess, (state, { offer }) =>
    offerAdapter.addOne(offer, { ...state, isLoading: false })
  ),
  on(offerActions.updateOfferSuccess, (state, { data }) =>
    offerAdapter.updateOne(data, { ...state, isLoading: false })
  ),
  on(offerActions.deleteOfferSuccess, (state, { id }) =>
    offerAdapter.removeOne(id, state)
  ),
  on(offerActions.fetchOffersSuccess, (state, { offers }) =>
    offerAdapter.setAll(offers, state)
  ),

  on(offerActions.updateLoading, function (state, { isLoading }) {
    return { ...state, isLoading };
  })
);

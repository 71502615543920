import { LocalStorage } from '@utils';

export interface SharedState {
  isDarkMode: boolean;
  isSideBarOpen: boolean;
  loading: {
    status: boolean;
    reqCounter: number;
  };
}
const localStorage = new LocalStorage();
export const initialState: SharedState = {
  isDarkMode: !!localStorage.get('darkMode'),
  isSideBarOpen: !!localStorage.get('sidebar'),
  loading: {
    status: false,
    reqCounter: 0,
  },
};

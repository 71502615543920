import { createReducer, on } from '@ngrx/store';
import { groupAdapter, groupInitialState } from './group.state';
import { groupAction } from './group.action';

export const groupReducer = createReducer(
  groupInitialState,
  on(groupAction.fetchGroupSuccess, (state, { data }) =>
    groupAdapter.setAll(data, state)
  ),
  on(groupAction.updateSuccess, (state, { data }) =>
    groupAdapter.updateOne(data, { ...state, isLoading: false })
  ),
  on(groupAction.addSuccess, (state, { data }) =>
    groupAdapter.addOne({ ...data }, { ...state, isLoading: false })
  ),
  on(groupAction.deleteSuccess, (state, { id }) =>
    groupAdapter.removeOne(id, state)
  ),
  on(groupAction.fetchDissociateUserSuccess, function (state, { data }) {
    return { ...state, dissociateUser: data };
  })
);

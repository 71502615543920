import { paginationConfig } from '@constants';
import { Appointment, Pagination, AppointmentPageModel } from '@models';

export interface AppointmentState {
  data: Appointment[];
  total: number;
  pagination: Pagination;
  filter: AppointmentPageModel.filter;
}

export const appointmentInitialState: AppointmentState = {
  data: [],
  total: 0,
  pagination: {
    offset: paginationConfig.defaultSize,
    page: 1,
  },
  filter: {
    date: {},
  },
};

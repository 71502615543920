import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { selectIsLoading } from '@stores/shared/shared.selector';
import { BaseComponent } from '@utils/BaseComponent';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  imports: [CommonModule, MatProgressSpinnerModule],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingComponent extends BaseComponent implements OnInit {
  isLoading$: Observable<boolean>;

  ngOnInit() {
    this.isLoading$ = this.store.select(selectIsLoading);
  }
}

import { createReducer, on } from '@ngrx/store';
import {
  setLoadingCounter,
  setLoadingStatus,
  toggleSidebar,
  toggleTheme,
} from './shared.actions';
import { initialState } from './shared.state';
import { produce } from 'immer';

export const SharedReducer = createReducer(
  initialState,
  on(toggleTheme, function (state) {
    return { ...state, isDarkMode: !state.isDarkMode };
  }),
  on(toggleSidebar, function (state) {
    return { ...state, isSideBarOpen: !state.isSideBarOpen };
  }),
  on(setLoadingStatus, function (state, { value }) {
    return produce(state, draft => {
      draft.loading.status = value;
    });
  }),
  on(setLoadingCounter, function (state, { value }) {
    return produce(state, draft => {
      draft.loading.reqCounter += value;
    });
  })
);

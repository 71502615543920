import { createReducer, on } from '@ngrx/store';
import { complaintInitialState } from './complaint.state';
import complaintActions from './complaint.action';

export const complaintReducer = createReducer(
  complaintInitialState,
  on(complaintActions.updateFilter, function (state, { data: filter }) {
    return { ...state, filter };
  }),
  on(complaintActions.updatePagination, function (state, { data: pagination }) {
    return { ...state, pagination };
  }),
  on(
    complaintActions.fetchComplaintSuccess,
    function (state, { data: { count, data } }) {
      return { ...state, data, total: count };
    }
  )
);

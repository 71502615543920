import { Injectable, inject } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import { BaseEffect, objectToFormData } from '@utils';
import { offerActions } from './offer.action';
import {
  catchError,
  exhaustMap,
  of,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs';
import { selectOrganization } from '@stores/auth/auth.selector';
import { MatDialogRefStore, OfferService } from '@services';
import { setErrorToast } from '@stores/shared/shared.actions';
import offerSelector from './offer.selector';

@Injectable()
export class OfferEffect extends BaseEffect {
  private service = inject(OfferService);
  private matDialogRefStore = inject(MatDialogRefStore);
  fetch$ = createEffect(() => {
    return this.action$.pipe(
      ofType(offerActions.fetchOffers),
      withLatestFrom(this.store.select(selectOrganization)),
      exhaustMap(([_, organization]) =>
        this.service
          .get({
            filter: {
              organizationId: organization.id,
              order: 'order asc',
              limit: 100,
            },
          })
          .pipe(
            switchMap(data =>
              of(offerActions.fetchOffersSuccess({ offers: data.offer }))
            ),
            catchError(() =>
              of(setErrorToast({ message: 'Failed to fetch offers.' }))
            )
          )
      )
    );
  });
  update$ = createEffect(() => {
    return this.action$.pipe(
      ofType(offerActions.update),
      exhaustMap(({ data, message, id, ref }) =>
        this.store.select(offerSelector.selectOfferById(id)).pipe(
          take(1),
          exhaustMap(offer =>
            this.service
              .update(id, objectToFormData({ ...(offer ?? {}), ...data }))
              .pipe(
                switchMap(_data => {
                  ref && this.matDialogRefStore.close(ref);
                  // Show a success toast with the provided message, or a default message
                  this.toastrService.success(
                    message?.success ?? 'Offer updated successfully.'
                  );

                  return of(
                    offerActions.fetchOffers(),
                    offerActions.updateLoading({ isLoading: false })
                  );
                }),
                catchError(() =>
                  of(
                    setErrorToast({
                      message: message?.error ?? 'Failed to update offer.',
                    })
                  )
                )
              )
          )
        )
      )
    );
  });

  toggleStatus$ = createEffect(() => {
    return this.action$.pipe(
      ofType(offerActions.toggleStatus),
      exhaustMap(({ id, data, message }) => {
        return this.service.toggleStatus(id, data).pipe(
          switchMap(({ offer }) => {
            this.toastrService.success(message.success);
            return of(
              offerActions.updateOfferSuccess({ data: { changes: offer, id } })
            );
          }),
          catchError(() =>
            of(
              setErrorToast({
                message: message.error ?? 'Failed to update offer status.',
              })
            )
          )
        );
      })
    );
  });
  archive$ = createEffect(() => {
    return this.action$.pipe(
      ofType(offerActions.archive),
      exhaustMap(({ id }) => {
        return this.service.archive(id).pipe(
          switchMap(() => {
            this.toastrService.success('Offer Archive successfully.');
            return of(offerActions.deleteOfferSuccess({ id }));
          }),
          catchError(() =>
            of(
              setErrorToast({
                message: 'Failed to archive offer. ',
              })
            )
          )
        );
      })
    );
  });
  create$ = createEffect(() => {
    return this.action$.pipe(
      ofType(offerActions.create),
      exhaustMap(({ data, ref }) =>
        this.store.select(selectOrganization).pipe(
          take(1),
          exhaustMap(organization =>
            this.service
              .create(
                objectToFormData({ ...data, organizationId: organization.id })
              )
              .pipe(
                switchMap(offer => {
                  if (ref) {
                    this.matDialogRefStore.close(ref);
                  }
                  this.toastrService.success(
                    'Offer or Event added successfully.'
                  );

                  return of(offerActions.createOfferSuccess({ offer }));
                }),
                catchError(err => {
                  let errorMessage = 'Unable to add offer, try again.';
                  if (err.error && typeof err.error === 'string') {
                    errorMessage = err.error;
                  } else if (err.error && typeof err.error === 'object') {
                    errorMessage = err.error.error || errorMessage;
                  }
                  return of(
                    setErrorToast({ message: errorMessage }),
                    offerActions.updateLoading({ isLoading: false })
                  );
                })
              )
          )
        )
      )
    );
  });

  delete$ = createEffect(() => {
    return this.action$.pipe(
      ofType(offerActions.delete),
      exhaustMap(({ id }) => {
        return this.service.delete(id).pipe(
          switchMap(() => {
            this.toastrService.success('Offer deleted successfully.');
            return of(offerActions.deleteOfferSuccess({ id }));
          }),
          catchError(() =>
            of(
              setErrorToast({
                message: 'Unable to delete offer.',
              })
            )
          )
        );
      })
    );
  });
  setLoading$ = createEffect(() => {
    return this.action$.pipe(
      ofType(offerActions.update, offerActions.create),
      exhaustMap(() => of(offerActions.updateLoading({ isLoading: true })))
    );
  });
}

import { createReducer, on } from '@ngrx/store';
import { testDriveInitialState } from '@stores/testDrive/testDrive.state';
import testdriveActions from './testDrive.actions';

export const testDriveReducer = createReducer(
  testDriveInitialState,
  on(testdriveActions.updateFilter, function (state, { data: filter }) {
    return { ...state, filter };
  }),
  on(
    testdriveActions.updatePagination,
    function (state, { pagination: pagination }) {
      return { ...state, pagination };
    }
  ),
  on(
    testdriveActions.fetchTestdriveSuccess,
    function (state, { registrations }) {
      return {
        ...state,
        registrations: registrations.data,
        total: registrations.count,
      };
    }
  ),
  on(testdriveActions.handleResolveSuccess, function (state, { ids }) {
    const newRegistrations = state.registrations.map(reg => {
      if (ids.includes(reg.id)) {
        return {
          ...reg,
          type: 'Register',
        };
      }
      return reg;
    });
    return {
      ...state,
      registrations: newRegistrations,
    };
  })
);

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, switchMap } from 'rxjs/operators';
import { superDashboardActions } from './super-dashboard.action';
import { setErrorToast } from '@stores/shared/shared.actions';
import { SuperDashboardService } from './super-dashboard.service';
import { of } from 'rxjs';

@Injectable()
export class SuperDashboardEffects {
  getQueriesCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(superDashboardActions.queryCount),
      exhaustMap(({ filter }) =>
        this.superDashboardService.getQueriesCount(filter).pipe(
          switchMap(data => {
            return of(superDashboardActions.queryCountSuccess({ data }));
          }),
          catchError(() =>
            of(
              setErrorToast({
                message: 'Unable to load queries refresh the page.',
              })
            )
          )
        )
      )
    );
  });
  getVisitorCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(superDashboardActions.visitorCount),
      exhaustMap(({ filter: { date } }) =>
        this.superDashboardService
          .getVisitorCount({ ...(date as Record<string, unknown>) })
          .pipe(
            switchMap(data => {
              return of(superDashboardActions.visitorCountSuccess({ data }));
            }),
            catchError(() =>
              of(
                setErrorToast({
                  message: 'Unable to load visitor count refresh the page.',
                })
              )
            )
          )
      )
    );
  });

  getAllQueriySource$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(superDashboardActions.querySources),
      exhaustMap(() =>
        this.superDashboardService.getAllQuerySource().pipe(
          switchMap(data => {
            return of(superDashboardActions.querySourcesSuccess({ data }));
          }),
          catchError(() =>
            of(
              setErrorToast({
                message: 'Unable to fetch query sources',
              })
            )
          )
        )
      )
    );
  });

  getOrgQueryByStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(superDashboardActions.allQueryStatus),
      exhaustMap(({ filter }) =>
        this.superDashboardService.getQueryByStatus(filter).pipe(
          switchMap(data => {
            return of(superDashboardActions.allQueryStatusSuccess({ data }));
          }),
          catchError(() =>
            of(
              setErrorToast({
                message: 'Unable to fetch Organization Queries by status',
              })
            )
          )
        )
      )
    );
  });

  getOrgQueryBySource$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(superDashboardActions.allQueriesBySource),
      exhaustMap(({ filter }) =>
        this.superDashboardService.getQueryBySource(filter).pipe(
          switchMap(data => {
            return of(
              superDashboardActions.allQueriesBySourceSuccess({ data })
            );
          }),
          catchError(() =>
            of(setErrorToast({ message: 'Unable to fetch queries by source!' }))
          )
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private superDashboardService: SuperDashboardService
  ) {}
}

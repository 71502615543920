import { SelectModel } from '@models';
import { TableData } from '@models/pages/ApiLogPageModel';

export interface ApiLogState {
  dropper: {
    type: SelectModel.Option<string>[];
    category: SelectModel.Option<string>[];
  };
  data: TableData[];
}
export const apiLogInitialState: ApiLogState = {
  dropper: {
    type: [],
    category: [],
  },
  data: [],
};

import { DashboardPageModel } from '@models';
export interface DashboardState {
  visitorCount: DashboardPageModel.VisitorCountResponse;
  queryCount: DashboardPageModel.QueryCountResponse;
  leadCount: DashboardPageModel.LeadResponse;
  complainCount: DashboardPageModel.ComplaintCountResponse[];
  botCount: DashboardPageModel.BotEngageTimeResponse;
  newVisitorCount: DashboardPageModel.NewVisitorCount;
}
export const dashboardInitialState: DashboardState = {
  visitorCount: {} as DashboardPageModel.VisitorCountResponse,
  queryCount: {} as DashboardPageModel.QueryCountResponse,
  leadCount: {} as DashboardPageModel.LeadResponse,
  botCount: {} as DashboardPageModel.BotEngageTimeResponse,
  complainCount: [],
  newVisitorCount: {} as DashboardPageModel.NewVisitorCount,
};

import { createReducer, on } from '@ngrx/store';
import { AUTH_INITIAL_STATE, Auth } from './auth.state';
import {
  changeOrganization,
  loginError,
  logoutSuccess,
  updateAuth,
} from './auth.actions';
import { produce } from 'immer';
export const authReducer = createReducer(
  AUTH_INITIAL_STATE,
  on(updateAuth, function (state, { type: _type, data }) {
    return produce(data, draft => {
      if (!draft.organization) {
        draft.organization = {} as Auth['organization'];
      }
      if (draft.setting) {
        draft.setting.baseUrl = AUTH_INITIAL_STATE['setting'].baseUrl;
        draft.setting.baseUrlWithoutVersion =
          AUTH_INITIAL_STATE['setting'].baseUrlWithoutVersion;
      }
    });
  }),
  on(logoutSuccess, function (_state) {
    return AUTH_INITIAL_STATE;
  }),
  on(loginError, (state, { remainingAttempts }): Auth => {
    const newState = { ...state, remainingAttempts };
    return newState;
  }),
  on(changeOrganization, function (state) {
    return {
      ...state,
      isSettingFetched: false,
      organization: {},
      setting: {},
    } as Auth;
  })
);

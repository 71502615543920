import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import {
  AUTO_LOGIN,
  autoLoginSuccess,
  CHANGE_PASSWORD,
  changeOrganization,
  getAuthUser,
  initialize,
  loginError,
  loginStart,
  logoutStart,
  logoutSuccess,
  updateAuth,
  updateSetting,
} from './auth.actions';
import {
  catchError,
  EMPTY,
  exhaustMap,
  filter,
  of,
  switchMap,
  take,
  takeWhile,
  tap,
} from 'rxjs';
import { AuthService } from '@services/auth.service';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { REDIRECT } from '@constants';
import { selectSetting } from '@stores/auth/auth.selector';
import { RoleUtils } from '@utils';
import { setErrorToast } from '@stores/shared/shared.actions';
import { selectCurrentRoute } from '@stores/router/router.selector';
@Injectable()
export class AuthEffect {
  private omitInitializeRoute = ['/forget-password', '/reset-password'];
  login$ = createEffect(() => {
    return this.action$.pipe(
      ofType(loginStart),
      exhaustMap(({ type: _type, ...credential }) => {
        return this.authService.login(credential).pipe(
          exhaustMap(data => {
            return of(getAuthUser({ token: data.id, redirect: true }));
          }),
          catchError(err => {
            const remainingAttempts = err?.error?.remainingAttempts;
            if (remainingAttempts !== undefined) {
              this._toast.error(
                err?.error?.message ?? 'Incorrect username or password.'
              );
              return of(loginError({ remainingAttempts: remainingAttempts }));
            } else {
              this._toast.error(
                err?.error?.message ?? 'Incorrect username or password.'
              );
              return EMPTY;
            }
          })
        );
      })
    );
  });
  autoLogin$ = createEffect(() => {
    return this.action$.pipe(
      ofType(AUTO_LOGIN),
      take(1),
      exhaustMap(() =>
        this.store.select(selectSetting).pipe(
          takeWhile(({ isSettingFetched }) => !isSettingFetched, true),
          filter(({ isSettingFetched }) => isSettingFetched),
          exhaustMap(authState => {
            if (authState.token) {
              return of(autoLoginSuccess({ data: authState, redirect: true }));
            }
            return EMPTY;
          })
        )
      )
    );
  });

  getUserSetting$ = createEffect(() => {
    return this.action$.pipe(
      ofType(getAuthUser),
      exhaustMap(({ token, organizationId, redirect = false }) => {
        return this.store.select(selectSetting).pipe(
          take(1),
          exhaustMap(
            ({
              token: token1,
              organization: { id: orgId = undefined } = {},
            }) => {
              const dashboardToken = token ?? token1;
              const dashboardOrganizationId = organizationId ?? orgId;
              if (!dashboardToken) {
                return of(logoutStart({}));
              }
              return this.authService
                .getSettings(dashboardToken, dashboardOrganizationId)
                .pipe(
                  exhaustMap(settings => {
                    return of(
                      updateAuth({
                        data: {
                          ...settings,
                          token: dashboardToken,
                          isSettingFetched: true,
                        },
                        redirect,
                      })
                    );
                  })
                );
            }
          )
        );
      })
    );
  });
  saveToDashBoard$ = createEffect(
    () => {
      return this.action$.pipe(
        ofType(updateAuth, autoLoginSuccess),
        tap(({ data }) => {
          this.authService.saveToken({
            token: data.token!,
            organizationId: data.organization?.id,
          });
        })
      );
    },
    { dispatch: false }
  );
  loginRedirect$ = createEffect(
    () => {
      return this.action$.pipe(
        ofType(updateAuth, autoLoginSuccess),
        exhaustMap(action =>
          this.store.select(selectSetting).pipe(
            takeWhile(({ isSettingFetched }) => !isSettingFetched, true),
            filter(({ isSettingFetched }) => isSettingFetched),
            exhaustMap(authState => {
              if (action.redirect) {
                if (
                  RoleUtils.checkRole(
                    'SuperOwner',
                    authState.user?.role?.name
                  ) &&
                  authState?.organization?.id
                ) {
                  this.router.navigate(['/dashboard']);
                } else
                  this.router.navigate([
                    REDIRECT[action.data.user!.role.name!],
                  ]);
              }

              return EMPTY;
            })
          )
        )
      );
    },
    {
      dispatch: false,
    }
  );

  logOut$ = createEffect(() => {
    return this.action$.pipe(
      ofType(logoutStart),
      concatLatestFrom(() => this.store.select(selectSetting)),
      exhaustMap(([{ message }, setting]) => {
        this.authService.deleteToken();
        if (setting.token) {
          this.authService.logOut().subscribe();
        }
        this.router.navigate(['/login'], { queryParams: { message } });
        return of(logoutSuccess());
      })
    );
  });
  changeOrganization$ = createEffect(() => {
    return this.action$.pipe(
      ofType(changeOrganization),
      exhaustMap(({ id }) => {
        return of(getAuthUser({ organizationId: id, redirect: true }));
      })
    );
  });
  changePassword$ = createEffect(() => {
    return this.action$.pipe(
      ofType(CHANGE_PASSWORD),
      exhaustMap(({ oldPassword, newPassword }) => {
        return this.authService.changePassword(oldPassword, newPassword).pipe(
          switchMap(() => {
            this.authService.logOut().subscribe();
            this.router.navigateByUrl('/login');
            this._toast.success('Password changed!');
            return of(logoutSuccess());
          }),
          catchError(error => {
            return of(
              setErrorToast({
                message: error.error.message,
              })
            );
          })
        );
      })
    );
  });
  initialize$ = createEffect(() => {
    return this.action$.pipe(
      ofType(initialize),
      exhaustMap(() => {
        return this.store.select(selectCurrentRoute).pipe(
          takeWhile(data => {
            return !data.url;
          }, true),
          filter(({ url }) => {
            return (
              !!url && !this.omitInitializeRoute.includes(url.split('?')[0])
            );
          }),
          exhaustMap(() => {
            const token = this.authService.getToken();
            if (token?.token) {
              return of(
                getAuthUser({
                  token: token.token,
                  organizationId: token.organizationId,
                  redirect: false,
                })
              );
            } else {
              this.router.navigateByUrl('/login');
              return EMPTY;
            }
          })
        );
      })
    );
  });
  updateSetting$ = createEffect(() => {
    return this.action$.pipe(
      ofType(updateSetting),
      exhaustMap(({ data, message }) =>
        this.store.select(selectSetting).pipe(
          take(1),
          exhaustMap(auth =>
            this.authService.updateSettings(data).pipe(
              exhaustMap(result => {
                this._toast.success(message.success, 'Setting Update');
                return of(
                  updateAuth({
                    data: {
                      ...auth,
                      setting: { ...auth.setting, ...result },
                    },
                  })
                );
              }),
              catchError(() =>
                of(
                  setErrorToast({
                    message: message.error,
                    title: 'Setting Update',
                  })
                )
              )
            )
          )
        )
      )
    );
  });

  constructor(
    private _toast: ToastrService,
    private action$: Actions,
    private store: Store,
    private authService: AuthService,
    private router: Router
  ) {}
}

import { createReducer, on } from '@ngrx/store';
import { dashboardInitialState } from './super-dashboard.state';
import { superDashboardActions } from './super-dashboard.action';
import { produce } from 'immer';

export const superDashboardReducer = createReducer(
  dashboardInitialState,
  on(superDashboardActions.queryCountSuccess, (state, { data }) => {
    return produce(state, draft => {
      draft.queryCount = data;
    });
  }),
  on(superDashboardActions.visitorCountSuccess, (state, { data }) => {
    return produce(state, draft => {
      draft.visitorCount = data;
    });
  }),
  on(superDashboardActions.allQueriesBySourceSuccess, (state, { data }) => {
    return produce(state, draft => {
      draft.queryBySources = data;
    });
  }),
  on(superDashboardActions.allQueryStatusSuccess, (state, { data }) => {
    return produce(state, draft => {
      draft.qeryByStatus = data;
    });
  }),
  on(superDashboardActions.querySourcesSuccess, (state, { data }) => {
    return produce(state, draft => {
      draft.querySources = data;
    });
  })
);

import { paginationConfig } from '@constants';
import { TestDrive, Pagination, TestDrivePageModel } from '@models';

export interface TestDriveState {
  registrations: TestDrive[];
  total: number;
  pagination: Pagination;
  filter: TestDrivePageModel.filter;
}

export const testDriveInitialState: TestDriveState = {
  registrations: [],
  total: 0,
  pagination: {
    offset: paginationConfig.defaultSize,
    page: 1,
  },

  filter: {
    type: 'all', // Initialize status with one of the allowed values.
  },
};

import { Injectable, inject } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import { BaseEffect, objectToFormData } from '@utils';
import { campaignActions } from './campaign.action';
import {
  EMPTY,
  catchError,
  exhaustMap,
  of,
  switchMap,
  take,
  withLatestFrom,
  forkJoin,
} from 'rxjs';
import { selectOrganization } from '@stores/auth/auth.selector';
import { MatDialogRefStore, CampaignService } from '@services';
import { setErrorToast } from '@stores/shared/shared.actions';
import campaignSelector from './campaign.selector';
import { omit } from 'lodash';
@Injectable()
export class CampaignEffect extends BaseEffect {
  private service = inject(CampaignService);
  private matDialogRefStore = inject(MatDialogRefStore);
  fetch$ = createEffect(() => {
    return this.action$.pipe(
      ofType(campaignActions.fetch),
      withLatestFrom(this.store.select(selectOrganization)),
      exhaustMap(([_, organization]) =>
        this.service
          .get({
            filter: {
              where: { organizationId: organization.id, archive: false },
              order: 'createdDate DESC',
              limit: 100,
            },
          })
          .pipe(
            switchMap(({ data }) =>
              of(campaignActions.fetchSuccess({ campaigns: data }))
            ),
            catchError(() =>
              of(setErrorToast({ message: 'Failed to fetch campaigns.' }))
            )
          )
      )
    );
  });
  update$ = createEffect(() => {
    return this.action$.pipe(
      ofType(campaignActions.update),
      exhaustMap(({ data, message, id, ref }) =>
        this.store.select(campaignSelector.selectCampaignById(id)).pipe(
          take(1),
          exhaustMap(campaign =>
            this.service
              .update(id, objectToFormData({ ...(campaign ?? {}), ...data }))
              .pipe(
                switchMap(_data => {
                  ref && this.matDialogRefStore.close(ref);
                  // Show a success toast with the provided message, or a default message
                  this.toastrService.success(
                    message?.success ?? 'Campaign updated successfully.'
                  );

                  return of(
                    campaignActions.fetch(),
                    campaignActions.updateLoading({ isLoading: false })
                  );
                }),
                catchError(() =>
                  of(
                    setErrorToast({
                      message: message?.error ?? 'Failed to update campaign.',
                    })
                  )
                )
              )
          )
        )
      )
    );
  });

  create$ = createEffect(() => {
    return this.action$.pipe(
      ofType(campaignActions.create),
      exhaustMap(({ data, ref }) =>
        this.store.select(selectOrganization).pipe(
          take(1),
          exhaustMap(organization =>
            this.service
              .create(
                objectToFormData({ ...data, organizationId: organization.id })
              )
              .pipe(
                switchMap(campaign => {
                  ref && this.matDialogRefStore.close(ref);
                  // Show a success toast with the provided message, or a default message
                  this.toastrService.success(
                    'Campaign or Event added successfully.'
                  );

                  return of(campaignActions.createSuccess({ campaign }));
                }),
                catchError(() =>
                  of(
                    setErrorToast({
                      message: 'Unable to add campaign try again.',
                    }),
                    campaignActions.updateLoading({ isLoading: false })
                  )
                )
              )
          )
        )
      )
    );
  });

  send$ = createEffect(() => {
    return this.action$.pipe(
      ofType(campaignActions.send),
      exhaustMap(({ data, ref }) =>
        this.store.select(selectOrganization).pipe(
          take(1),
          switchMap(organization => {
            const campaignIdsArray = Array.isArray(data.campaignIds)
              ? data.campaignIds
              : [data.campaignIds];

            return forkJoin(
              campaignIdsArray.map(campaignId =>
                this.service.send(
                  {
                    ...omit(data, 'campaignIds'),
                    organizationId: organization.id,
                  },
                  campaignId
                )
              )
            ).pipe(
              switchMap(() => {
                ref && this.matDialogRefStore.close(ref);
                this.toastrService.success('Campaigns sent successfully.');
                return EMPTY;
              }),
              catchError(() =>
                of(
                  setErrorToast({
                    message: 'Unable to send campaigns. Please try again.',
                  }),
                  campaignActions.updateLoading({ isLoading: false })
                )
              )
            );
          })
        )
      )
    );
  });

  delete$ = createEffect(() => {
    return this.action$.pipe(
      ofType(campaignActions.delete),
      exhaustMap(({ id }) => {
        return this.service.delete(id).pipe(
          switchMap(() => {
            this.toastrService.success('Campaign deleted successfully.');
            return of(campaignActions.deleteSuccess({ id }));
          }),
          catchError(() =>
            of(
              setErrorToast({
                message: 'Unable to delete campaign.',
              })
            )
          )
        );
      })
    );
  });
  archive$ = createEffect(() => {
    return this.action$.pipe(
      ofType(campaignActions.archive),
      exhaustMap(({ id }) => {
        return this.service.archive(id).pipe(
          switchMap(() => {
            this.toastrService.success('Campaign archived successfully.');
            return of(campaignActions.deleteSuccess({ id }));
          }),
          catchError(() =>
            of(
              setErrorToast({
                message: 'Unable to archive campaign.',
              })
            )
          )
        );
      })
    );
  });

  setLoading$ = createEffect(() => {
    return this.action$.pipe(
      ofType(campaignActions.update, campaignActions.create),
      exhaustMap(() => of(campaignActions.updateLoading({ isLoading: true })))
    );
  });
}

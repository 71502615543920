import { paginationConfig } from '@constants';
import { Pagination, likeDislikeQuery, likeDisLikePageModel } from '@models';

export interface LikeDislikeQueryState {
  data: likeDislikeQuery[];
  total: number;
  pagination: Pagination;
  filter: likeDisLikePageModel.filter;
}

export const likeDislikeInitialState: LikeDislikeQueryState = {
  data: [],
  total: 0,
  pagination: {
    offset: paginationConfig.defaultSize,
    page: 1,
  },
  filter: {
    order: {
      direction: 'desc',
      field: 'createdDate',
    },
  },
};

import { Injectable, inject } from '@angular/core';
import { AgentPageModel } from '@models';
import { createEffect, ofType } from '@ngrx/effects';
import { AgentService } from '@services/agent.service';
import { selectOrganization } from '@stores/auth/auth.selector';
import { setErrorToast } from '@stores/shared/shared.actions';
import { BaseEffect, dayjs } from '@utils';
import { pick } from 'lodash';
import {
  EMPTY,
  catchError,
  combineLatest,
  exhaustMap,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import agentActions from './agent.actions';
import agentSelector from './agent.selector';

@Injectable()
export class AgentEffect extends BaseEffect {
  private service = inject(AgentService);

  getFilter(filter: AgentPageModel.filter) {
    const queryFilter: Record<string, unknown> = pick(filter, [
      'search',
      'source',
    ]);

    if (filter.dateRange?.startDate) {
      queryFilter['startDate'] = filter.dateRange.startDate;
    }
    if (filter.dateRange?.endDate) {
      queryFilter['endDate'] = filter.dateRange.endDate;
    }
    if (filter.department) {
      queryFilter['departmentId'] = filter.department.map(v => v.id).join('');
    }
    if (filter.agents) {
      if (!queryFilter['agents']) {
        queryFilter['agents'] = [];
      }
      (queryFilter['agents'] as Array<any>).push({
        id: filter.agents,
      });
    }

    return queryFilter;
  }

  $fetchAgentAnalytic = createEffect(() => {
    return this.action$.pipe(
      ofType(agentActions.fetchAgents),
      withLatestFrom(
        combineLatest([
          this.store.select(selectOrganization),
          this.store.select(agentSelector.selectFilter),
        ])
      ),
      switchMap(([_, [organization, filter]]) =>
        this.service
          .getAnalytics({
            organizationId: organization.id,
            isPagination: false,
            ...this.getFilter(filter),
          })
          .pipe(
            switchMap(data => of(agentActions.fetchAgentsSuccess({ data }))),

            catchError(_error =>
              of(setErrorToast({ message: 'Unable to load data.' }))
            )
          )
      )
    );
  });

  exportData$ = createEffect(() => {
    return this.action$.pipe(
      ofType(agentActions.exportToCsv),
      withLatestFrom(
        combineLatest([
          this.store.select(selectOrganization),
          this.store.select(agentSelector.selectFilter),
        ])
      ),
      exhaustMap(([_, [organization, filter]]) =>
        this.service
          .exportToCsv({
            organizationId: organization.id,
            isPagination: false,
            ...this.getFilter(filter),
          })
          .pipe(
            exhaustMap(response => {
              if (window.confirm('Do you want to save agent analytics?')) {
                this.service.saveFile(
                  response,
                  `agent-analytics-${dayjs().format('YYYY-MM-DD hh:mm:ss A')}.csv`
                );
              }
              return EMPTY;
            }),
            catchError(() =>
              of(
                setErrorToast({
                  message: 'Some thing error occurred try again!.',
                })
              )
            )
          )
      )
    );
  });
}

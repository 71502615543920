import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { queryActions } from './query.action';
import {
  EMPTY,
  catchError,
  exhaustMap,
  map,
  of,
  take,
  withLatestFrom,
  combineLatest,
  switchMap,
} from 'rxjs';

import { QueryService } from '@services';
import { setErrorToast } from '@stores/shared/shared.actions';
import dayjs from 'dayjs';
import { selectOrganization } from '@stores/auth/auth.selector';
import { Router } from '@angular/router';
import { omit } from 'lodash';
import { selectFilterAndPagination } from './query.selector';
import { Pagination, QueryPageModel } from '@models';
import { inject } from '@angular/core';

@Injectable()
export class QueryEffects {
  private service = inject(QueryService);
  private filter: Record<string, string> = {
    senderId: 'source',
  };
  constructor(
    private action$: Actions,
    private store: Store,
    private queryService: QueryService,
    private route: Router
  ) {}
  loadQuery$ = createEffect(() => {
    return this.action$.pipe(
      ofType(queryActions.fetchQuery),
      exhaustMap(() =>
        this.store
          .select(selectFilterAndPagination)
          .pipe(withLatestFrom(this.store.select(selectOrganization)))
          .pipe(
            take(1),
            exhaustMap(([params, organization]) => {
              return this.queryService
                .get({
                  filter: this.getFilter(
                    {
                      ...params.filter,
                      organizationId: organization.id,
                    },
                    params.pagination
                  ),
                })

                .pipe(
                  exhaustMap(data =>
                    of(queryActions.fetchQuerySuccess({ data }))
                  ),
                  catchError(() =>
                    of(setErrorToast({ message: 'Error loading queries' }))
                  )
                );
            })
          )
      )
    );
  });
  updateFilter$ = createEffect(
    () => {
      return this.action$.pipe(
        ofType(queryActions.updateFilter),
        exhaustMap(({ data }) => {
          const queryParams = Object.entries(
            omit(data, ['dateRange', 'order'])
          ).reduce(
            (currentFilterParams, [key, value]) => {
              if (Array.isArray(value)) {
                value = value.join(',');
              }
              currentFilterParams[(this.filter[key] || key) as string] =
                value.toString();

              return currentFilterParams;
            },
            {} as Record<string, unknown>
          );
          if (data['dateRange']?.startDate) {
            queryParams['startDate'] = data['dateRange']['startDate'];
          } else {
            queryParams['startDate'] = dayjs()
              .subtract(6, 'month')
              .toISOString();
          }
          if (data['dateRange']?.endDate) {
            queryParams['endDate'] = data['dateRange']['endDate'];
          }
          this.route.navigate([], { queryParams, replaceUrl: true });
          return of(queryActions.updateFilterSuccess({ data }));
        })
      );
    },
    { dispatch: true }
  );
  updatePagination$ = createEffect(
    () => {
      return this.action$.pipe(
        ofType(queryActions.updatePagination),
        map(({ data }) => {
          this.route.navigate([], {
            queryParams: { ...data },
            replaceUrl: true,
            queryParamsHandling: 'merge',
          });
          return EMPTY;
        })
      );
    },
    { dispatch: false }
  );
  exportToCsv$ = createEffect(() => {
    return this.action$.pipe(
      ofType(queryActions.exportToCsv),
      concatLatestFrom(() => this.store.select(selectFilterAndPagination)),
      concatLatestFrom(() => this.store.select(selectOrganization)),
      exhaustMap(([[_, data], organization]) => {
        const filter = this.getFilter(
          {
            ...data.filter,
            organizationId: organization.id,
          },
          data.pagination
        );
        delete filter['limit'];
        delete filter['page'];
        return this.queryService.exportToCsv(filter).pipe(
          exhaustMap(data => {
            if (window.confirm('Do you want to save the file?')) {
              this.queryService.saveFile(data);
            }
            return EMPTY;
          }),
          catchError(() =>
            of(setErrorToast({ message: 'Internal server error ' }))
          )
        );
      })
    );
  });
  fetchIntentIds$ = createEffect(() => {
    return this.action$.pipe(
      ofType(queryActions.fetchIntentIds),
      concatLatestFrom(() =>
        combineLatest([
          this.store.select(selectOrganization),
          this.store.select(selectFilterAndPagination),
        ])
      ),
      exhaustMap(([_, [organization, data]]) => {
        const { filter } = data;
        const startDate =
          filter['dateRange']?.startDate ||
          dayjs().subtract(6, 'month').toISOString();
        const endDate = filter['dateRange']?.endDate || dayjs().toISOString();

        return this.service
          .getIntentIds(organization.id, startDate, endDate)
          .pipe(
            switchMap(({ intentIds: data }) =>
              of(queryActions.fetchIntentIdsSuccess({ data }))
            ),
            catchError(() =>
              of(
                setErrorToast({
                  message: 'Unable to load IntentIds. Try Again!.',
                })
              )
            )
          );
      })
    );
  });

  private getFilter(filter: QueryPageModel.filter, pagination: Pagination) {
    const queryPram: Record<string, any> = {
      page: pagination.page,
      limit: pagination.offset,
    };
    const where = Object.entries(
      omit(filter, ['dateRange', 'query', 'intentId', 'source', 'order'])
    ).reduce(
      (currentQuery, [key, value]) => {
        currentQuery[key] = value;
        return currentQuery;
      },
      {} as Record<string, unknown>
    );
    if (filter['query']) {
      where['query'] = { like: '.*' + filter['query'] + '.*', options: 'i' };
    }
    if (filter['intentId']) {
      where['intentId'] = {
        like: '.*' + filter['intentId'] + '.*',
        options: 'i',
        inq: filter['intentId'],
      };
    }
    if (filter['dateRange']?.startDate) {
      where['createdDate'] = {
        ...(filter['createdDate'] || {}),
        gte: new Date(filter['dateRange'].startDate).toISOString(),
      };
    } else {
      where['createdDate'] = {
        ...(filter['createdDate'] || {}),
        // gte: new Date('2024-04-05').toISOString(),
        gte: dayjs().subtract(6, 'month').toISOString(),
      };
    }
    if (filter['dateRange']?.endDate) {
      where['createdDate'] = {
        ...(where['createdDate'] || {}),
        lte: dayjs(filter['dateRange'].endDate).endOf('D').toISOString(),
      };
      if ((where['createdDate'] as Record<string, any>)['gte']) {
        where['createdDate'] = {
          between: [
            (<any>where['createdDate']).gte,
            (<any>where['createdDate']).lte,
          ],
        };
      }
    }
    if (filter['source']) {
      where['senderId'] = { inq: filter['source'] };
    }
    if (filter['order']) {
      queryPram['order'] =
        `${filter['order']?.key} ${filter['order']?.direction}`;
    } else {
      queryPram['order'] = 'createdDate DESC';
    }
    queryPram['where'] = where;
    return queryPram;
  }
}

import { Routes } from '@angular/router';
import ROLE from './constants/role';
import { authGuard } from '@guards/auth.guard';
import { organizationReducer } from '@stores/organization/organization.reducer';
import { ORGANIZATION_STATE_NAME } from '@stores/organization/organization.selector';
import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { OrganizationEffects } from '@stores/organization/organization.effect';
import { DashboardEffects } from '@stores/dashboard/dashboard.effect';
import { DASHBOARD_STATE_NAME } from '@stores/dashboard/dashboard.selector';
import { dashboardReducer } from '@stores/dashboard/dashboard.reducer';
import { UserEffects } from '@stores/users/user.effect';
import { userReducer } from '@stores/users/user.reducer';
import { USER_STATE_NAME } from '@stores/users/user.selector';
import { QUERY_STATE_NAME } from '@stores/query/query.selector';
import { queryReducer } from '@stores/query/query.reducer';
import { QueryEffects } from '@stores/query/query.effect';
import { LEAD_STATE_NAME } from '@stores/lead/lead.selector';
import { leadReducer } from '@stores/lead/lead.reducer';
import { LeadEffect } from '@stores/lead/lead.effect';
import { GROUP_STATE_NAME } from '@stores/group/group.selector';
import { groupReducer } from '@stores/group/group.reducer';
import { GroupEffect } from '@stores/group/group.effect';
import { offerReducer } from '@stores/offer/offer.reducer';
import { OFFER_STATE_NAME } from '@stores/offer/offer.selector';
import { OfferEffect } from '@stores/offer/offer.effect';

import { AGENT_STATE_NAME } from '@stores/agent/agent.selector';
import { agentReducer } from '@stores/agent/agent.reducer';
import { AgentEffect } from '@stores/agent/agent.effect';
import { DEPARTMENT_STATE_NAME } from '@stores/department/department.selector';
import departmentReducer from '@stores/department/department.reducer';
import { DepartmentEffect } from '@stores/department/department.effect';
import { COMPLAINT_STATE_NAME } from '@stores/complaint/complaint.selector';
import { complaintReducer } from '@stores/complaint/complaint.reducer';
import { ComplaintEffect } from '@stores/complaint/complaint.effect';
import { FEEDBACK_STATE_NAME } from '@stores/feedback/feedback.selector';
import { feedbackReducer } from '@stores/feedback/feedback.reducer';
import { FeedbackEffect } from '@stores/feedback/feedback.effect';
import { APPOINTMENT_STATE_NAME } from '@stores/appointment/appointment.selector';
import { appointmentReducer } from '@stores/appointment/appointment.reducer';
import { AppointmentEffect } from '@stores/appointment/appointment.effect';
import { CAMPAIGN_STATE_NAME } from '@stores/campaign/campaign.selector';
import { campaignReducer } from '@stores/campaign/campaign.reducer';
import { CampaignEffect } from '@stores/campaign/campaign.effect';
import { API_LOGS_STATE_NAME } from '@stores/api-logs/apiLogs.selector';
import { apiLogReducer } from '@stores/api-logs/apiLogs.reducer';
import { ApiLogsEffect } from '@stores/api-logs/apiLogs.effect';
import { RESET_STATE_NAME } from '@stores/reset/reset/reset.selector';
import { resetReducer } from '@stores/reset/reset/reset.reducer';
import { ResetEffect } from '@stores/reset/reset/reset.effect';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { UnauthorizeComponent } from './pages/unauthorize/unauthorize.component';
import { VISITOR_STATE_NAME } from '@stores/visitors/visitor.selector';
import { visitorReducer } from '@stores/visitors/visitor.reducer';
import { VisitorEffect } from '@stores/visitors/visitor.effect';
import { VisitorService } from '@services/visitor.service';
import { testDriveReducer } from '@stores/testDrive/testDrive.reducer';
import { TEST_DRIVE_STATE_NAME } from '@stores/testDrive/testDrive.selector';
import { TestDriveEffect } from '@stores/testDrive/testDrive.effect';
import {
  TestDriveService,
  ResetService,
  LeadService,
  GroupService,
} from '@services';
import { SUPER_DASHBOARD_STATE_NAME } from '@stores/super-dashboard/super-dashboard.selector';
import { superDashboardReducer } from '@stores/super-dashboard/super-dashboard.reducer';
import { SuperDashboardEffects } from '@stores/super-dashboard/super-dashboard.effect';
import { SuperDashboardService } from '@stores/super-dashboard/super-dashboard.service';
import { LikeDislikeService } from '@services/likeDislikeQuery.service';
import { LIKEDISLIKE_STATE_NAME } from '@stores/likeDislikeQuery/likeDislike.selector';
import { likeDislikeReducer } from '@stores/likeDislikeQuery/likeDislike.reducer';
import { LikeDislikeEffect } from '@stores/likeDislikeQuery/likeDislike.effect';

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },

  {
    path: 'login',
    data: {
      showLayout: true,
      layout: 'auth',
    },
    loadComponent() {
      return import('./pages/login/login.component').then(
        m => m.LoginComponent
      );
    },
  },
  {
    path: 'forget-password',
    data: {
      showLayout: true,
      layout: 'auth',
    },
    providers: [
      provideState(RESET_STATE_NAME, resetReducer),
      provideEffects(ResetEffect),
      ResetService,
    ],
    loadComponent() {
      return import('./pages/forget-password/forget-password.component').then(
        m => m.ForgetPasswordComponent
      );
    },
  },
  {
    path: 'reset-password',
    data: {
      showLayout: true,
      layout: 'auth',
    },
    providers: [
      provideState(RESET_STATE_NAME, resetReducer),
      provideEffects(ResetEffect),
      ResetService,
    ],
    loadComponent() {
      return import('./pages/reset-password/reset-password.component').then(
        m => m.ResetPasswordComponent
      );
    },
  },
  {
    path: 'organizations',
    data: {
      showLayout: true,
      id: 'organization',
      role: [ROLE.SuperOwner],
    },
    canActivate: [authGuard],
    providers: [
      provideState(ORGANIZATION_STATE_NAME, organizationReducer),
      provideEffects([OrganizationEffects]),
    ],

    loadComponent() {
      return import('./pages/organization/organization.component').then(
        m => m.OrganizationComponent
      );
    },
  },
  {
    path: 'setting',
    data: {
      id: 'setting',

      showLayout: true,
      allowedRole: [
        ROLE.SuperOwner,
        ROLE.Admin,
        ROLE.Manager,
        ROLE.Marketing,
        ROLE.Service,
        ROLE.Support,
        ROLE.Supervisor,
      ],
    },
    canActivate: [authGuard],
    providers: [
      provideState(ORGANIZATION_STATE_NAME, organizationReducer),
      provideEffects([OrganizationEffects]),
    ],

    loadComponent() {
      return import('./pages/setting/setting.component').then(
        m => m.SettingComponent
      );
    },
  },
  {
    path: 'query',
    data: {
      showLayout: true,
      allowedRole: [
        ROLE.SuperOwner,
        ROLE.Admin,
        ROLE.Manager,
        ROLE.Support,
        ROLE.Supervisor,
        ROLE.Marketing,
        ROLE.Service,
      ],
      id: 'query',
    },
    canActivate: [authGuard],
    providers: [
      provideState(QUERY_STATE_NAME, queryReducer),
      provideEffects([QueryEffects]),
    ],
    loadComponent() {
      return import('./pages/query/query.component').then(
        m => m.QueryComponent
      );
    },
  },
  {
    path: 'leads',
    data: {
      showLayout: true,
      id: 'lead',
      allowedRole: [
        ROLE.SuperOwner,
        ROLE.Admin,
        ROLE.Service,
        ROLE.Supervisor,
        ROLE.Manager,
        ROLE.Marketing,
        ROLE.Support,
      ],
    },
    canActivate: [authGuard],
    providers: [
      provideState(LEAD_STATE_NAME, leadReducer),
      provideState(GROUP_STATE_NAME, groupReducer),
      provideEffects([LeadEffect, GroupEffect]),
      LeadService,
      GroupService,
    ],
    loadComponent() {
      return import('./pages/lead/lead.component').then(m => m.LeadComponent);
    },
  },
  {
    path: 'users',
    data: {
      id: 'user',
      showLayout: true,
      allowedRole: [
        ROLE.SuperOwner,
        ROLE.Admin,
        ROLE.Support,
        ROLE.Supervisor,
        ROLE.Manager,
        ROLE.Service,
        ROLE.Marketing,
      ],
    },
    canActivate: [authGuard],
    providers: [
      provideState(USER_STATE_NAME, userReducer),
      provideEffects([UserEffects]),
    ],

    loadComponent() {
      return import('./pages/users/users.component').then(
        m => m.UsersComponent
      );
    },
  },
  {
    path: 'dashboard',
    data: {
      id: 'dashboard',
      showLayout: true,
      allowedRole: [
        ROLE.SuperOwner,
        ROLE.Admin,
        ROLE.Supervisor,
        ROLE.Manager,
        ROLE.Service,
        ROLE.Supervisor,
        ROLE.Support,
        ROLE.Marketing,
      ],
    },
    providers: [
      provideState(DASHBOARD_STATE_NAME, dashboardReducer),
      provideEffects([DashboardEffects]),
    ],
    canActivate: [authGuard],

    loadComponent() {
      return import('./pages/dashboard/dashboard.component').then(
        m => m.DashboardComponent
      );
    },
  },

  {
    path: 'SuperDashboard',
    data: {
      id: 'superDashboard',
      showLayout: true,
      allowedRole: [ROLE.SuperOwner],
    },
    providers: [
      provideState(SUPER_DASHBOARD_STATE_NAME, superDashboardReducer),
      provideState(ORGANIZATION_STATE_NAME, organizationReducer),
      provideEffects([SuperDashboardEffects, OrganizationEffects]),
      SuperDashboardService,
    ],
    canActivate: [authGuard],

    loadComponent() {
      return import('./pages/super-dashboard/super-dashboard.component').then(
        m => m.SuperDashboardComponent
      );
    },
  },

  {
    path: 'offers',
    data: {
      id: 'offer',
      showLayout: true,
      allowedRole: [
        ROLE.SuperOwner,
        ROLE.Admin,
        ROLE.Manager,
        ROLE.Marketing,
        ROLE.Support,
        ROLE.Service,
        ROLE.Supervisor,
      ],
    },
    canActivate: [authGuard],
    providers: [
      provideState(OFFER_STATE_NAME, offerReducer),
      provideEffects([OfferEffect]),
    ],
    loadComponent() {
      return import('./pages/offer/offer.component').then(
        m => m.OfferComponent
      );
    },
  },
  {
    path: 'departments',
    data: {
      id: 'department',
      showLayout: true,
      allowedRole: [
        ROLE.SuperOwner,
        ROLE.Admin,
        ROLE.Manager,
        ROLE.Supervisor,
        ROLE.Marketing,
        ROLE.Service,
        ROLE.Support,
      ],
    },
    canActivate: [authGuard],
    providers: [
      provideState(DEPARTMENT_STATE_NAME, departmentReducer),
      provideEffects([DepartmentEffect]),
    ],
    loadComponent() {
      return import('./pages/department/department.component').then(
        m => m.DepartmentComponent
      );
    },
  },
  {
    path: 'chats',
    data: {
      id: 'chatHistory',
      showLayout: true,
      allowedRole: [
        ROLE.Service,
        ROLE.SuperOwner,
        ROLE.Admin,
        ROLE.Manager,
        ROLE.Supervisor,
        ROLE.Support,
        ROLE.Marketing,
      ],
    },
    canActivate: [authGuard],
    providers: [],
    loadComponent() {
      return import('./pages/chat/chat.component').then(m => m.ChatComponent);
    },
  },
  {
    path: 'agents/analytics',
    data: {
      id: 'agent',
      showLayout: true,
      allowedRole: [
        ROLE.SuperOwner,
        ROLE.Admin,
        ROLE.Support,
        ROLE.Service,
        ROLE.Marketing,
        ROLE.Manager,
        ROLE.Supervisor,
      ],
    },
    canActivate: [authGuard],
    providers: [
      provideState(AGENT_STATE_NAME, agentReducer),
      provideState(DEPARTMENT_STATE_NAME, departmentReducer),
      provideEffects([AgentEffect, DepartmentEffect]),
    ],
    loadComponent() {
      return import('./pages/agent-analytics/agent-analytics.component').then(
        m => m.AgentAnalyticsComponent
      );
    },
  },
  {
    path: 'systemLoggers',
    data: {
      id: 'logger',
      showLayout: true,
      allowedRole: [ROLE.SuperOwner, ROLE.Admin],
    },
    canActivate: [authGuard],
    providers: [],
    loadComponent() {
      return import('./pages/system-logger/system-logger.component').then(
        m => m.SystemLoggerComponent
      );
    },
  },
  {
    path: 'testdrive',
    data: {
      showLayout: true,
      id: 'testDrive',
      allowedRole: [
        ROLE.SuperOwner,
        ROLE.Admin,
        ROLE.Manager,
        ROLE.Supervisor,
        ROLE.Support,
        ROLE.Marketing,
        ROLE.Service,
      ],
    },
    canActivate: [authGuard],
    providers: [
      provideState(TEST_DRIVE_STATE_NAME, testDriveReducer),

      provideEffects([TestDriveEffect]),
      TestDriveService,
    ],
    loadComponent() {
      return import('./pages/testdrive/testdrive/testdrive.component').then(
        m => m.TestdriveComponent
      );
    },
  },

  {
    path: 'group',
    data: {
      showLayout: true,
      id: 'group',
      allowedRole: [
        ROLE.SuperOwner,
        ROLE.Admin,
        ROLE.Manager,
        ROLE.Supervisor,
        ROLE.Support,
        ROLE.Marketing,
        ROLE.Service,
      ],
    },
    canActivate: [authGuard],
    providers: [
      provideState(GROUP_STATE_NAME, groupReducer),
      provideEffects([GroupEffect]),
      GroupService,
    ],
    loadComponent() {
      return import('./pages/group/group.component').then(
        m => m.GroupComponent
      );
    },
  },
  {
    path: 'likeDislikeQuery',
    data: {
      showLayout: true,
      id: 'likeDislikeQuery',
      allowedRole: [
        ROLE.SuperOwner,
        ROLE.Admin,
        ROLE.Manager,
        ROLE.Support,
        ROLE.Supervisor,
        ROLE.Service,
        ROLE.Marketing,
      ],
    },
    canActivate: [authGuard],
    providers: [
      provideState(LIKEDISLIKE_STATE_NAME, likeDislikeReducer),
      provideEffects([LikeDislikeEffect]),
      LikeDislikeService,
    ],
    loadComponent() {
      return import(
        './pages/like-dislike-query/like-dislike-query.component'
      ).then(m => m.LikeDislikeQueryComponent);
    },
  },
  {
    path: 'complaints',
    data: {
      id: 'complaint',
      showLayout: true,
      allowedRole: [
        ROLE.SuperOwner,
        ROLE.Admin,
        ROLE.Support,
        ROLE.Service,
        ROLE.Manager,
        ROLE.Supervisor,
        ROLE.Marketing,
      ],
    },
    canActivate: [authGuard],
    providers: [
      provideState(COMPLAINT_STATE_NAME, complaintReducer),
      provideEffects([ComplaintEffect]),
    ],
    loadComponent() {
      return import('./pages/complaints/complaints.component').then(
        m => m.ComplaintsComponent
      );
    },
  },
  {
    path: 'appointments',
    data: {
      showLayout: true,
      id: 'appointment',
      allowedRole: [
        ROLE.SuperOwner,
        ROLE.Admin,
        ROLE.Manager,
        ROLE.Support,
        ROLE.Service,
        ROLE.Supervisor,
        ROLE.Marketing,
      ],
    },
    canActivate: [authGuard],
    providers: [
      provideState(APPOINTMENT_STATE_NAME, appointmentReducer),
      provideEffects([AppointmentEffect]),
    ],
    loadComponent() {
      return import('./pages/appointment/appointment.component').then(
        m => m.AppointmentComponent
      );
    },
  },
  {
    path: 'feedbacks',
    data: {
      id: 'feedback',
      showLayout: true,
      allowedRole: [
        ROLE.SuperOwner,
        ROLE.Admin,
        ROLE.Manager,
        ROLE.Support,
        ROLE.Service,
        ROLE.Supervisor,
        ROLE.Marketing,
      ],
    },
    canActivate: [authGuard],
    providers: [
      provideState(FEEDBACK_STATE_NAME, feedbackReducer),
      provideEffects([FeedbackEffect]),
    ],
    loadComponent() {
      return import('./pages/feedback/feedback.component').then(
        m => m.FeedbackComponent
      );
    },
  },
  {
    path: 'campaigns',
    data: {
      id: 'campaign',
      showLayout: true,
      allowedRole: [
        ROLE.SuperOwner,
        ROLE.Admin,
        ROLE.Marketing,
        ROLE.Manager,
        ROLE.Service,
        ROLE.Supervisor,
        ROLE.Support,
      ],
    },
    canActivate: [authGuard],
    providers: [
      provideState(CAMPAIGN_STATE_NAME, campaignReducer),
      provideEffects([CampaignEffect]),
    ],
    loadComponent() {
      return import('./pages/campaign/campaign.component').then(
        m => m.CampaignComponent
      );
    },
  },
  {
    path: 'apiLogs',
    data: {
      id: 'apiLog',
      showLayout: true,
      allowedRole: [ROLE.SuperOwner, ROLE.Admin, ROLE.Marketing],
    },
    canActivate: [authGuard],
    providers: [
      provideState(API_LOGS_STATE_NAME, apiLogReducer),
      provideEffects([ApiLogsEffect]),
    ],
    loadComponent() {
      return import('./pages/api-log/api-log.component').then(
        m => m.ApiLogComponent
      );
    },
  },
  {
    path: 'visitors',
    data: {
      showLayout: true,
      allowedRole: [
        ROLE.SuperOwner,
        ROLE.Admin,
        ROLE.Manager,
        ROLE.Marketing,
        ROLE.Service,
        ROLE.Support,
        ROLE.Supervisor,
      ],
      id: 'visitor',
    },
    canActivate: [authGuard],
    providers: [
      provideState(VISITOR_STATE_NAME, visitorReducer),
      provideState(GROUP_STATE_NAME, groupReducer),
      provideEffects([VisitorEffect]),
      VisitorService,
    ],
    loadComponent() {
      return import('./pages/visitor/visitor.component').then(
        m => m.VisitorComponent
      );
    },
  },
  {
    path: 'unAuthorize',

    data: {
      showLayout: false,
    },
    component: UnauthorizeComponent,
  },
  {
    path: 'change-password',
    data: {
      showLayout: true,
      allowedRole: [
        ROLE.SuperOwner,
        ROLE.Admin,
        ROLE.Service,
        ROLE.Support,
        ROLE.Manager,
        ROLE.Training,
        ROLE.Marketing,
        ROLE.Supervisor,
      ],
      id: 'changePassword',
    },
    canActivate: [authGuard],
    loadComponent() {
      return import('./pages/change-password/change-password.component').then(
        m => m.ChangePasswordComponent
      );
    },
  },
  {
    path: '**',
    pathMatch: 'full',
    data: {
      showLayout: false,
    },
    component: NotFoundComponent,
  },
];

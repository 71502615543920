import { Injectable, inject } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import { BaseEffect } from '@utils/BaseEffect';
import { groupAction } from './group.action';
import {
  combineLatest,
  exhaustMap,
  of,
  switchMap,
  withLatestFrom,
  catchError,
} from 'rxjs';
import { selectOrganization } from '@stores/auth/auth.selector';

import { GroupService } from '@services/group.service';
import { take } from 'rxjs';
import { MatDialogRefStore } from '@services/matDialogRefStore.service';
import { setErrorToast } from '@stores/shared/shared.actions';

@Injectable()
export class GroupEffect extends BaseEffect {
  private service = inject(GroupService);
  private matDialogRefStore = inject(MatDialogRefStore);
  fetchGroup$ = createEffect(() => {
    return this.action$.pipe(
      ofType(groupAction.fetchGroup),
      withLatestFrom(combineLatest([this.store.select(selectOrganization)])),
      exhaustMap(([_, [organization]]) => {
        return this.service
          .get(organization.id)
          .pipe(
            switchMap(({ data }) => of(groupAction.fetchGroupSuccess({ data })))
          );
      })
    );
  });
  add$ = createEffect(() => {
    return this.action$.pipe(
      ofType(groupAction.add),
      exhaustMap(({ data, ref }) =>
        this.store.select(selectOrganization).pipe(
          take(1),
          exhaustMap(organization =>
            this.service.add(data, organization.id).pipe(
              switchMap(data => {
                ref && this.matDialogRefStore.close(ref);
                this.toastrService.success('Group added successfully.');

                return of(
                  groupAction.addSuccess({ data }),
                  groupAction.updateLoader({ isLoading: false })
                );
              }),
              catchError(({ error }) => {
                return of(
                  setErrorToast({
                    message:
                      error?.message ??
                      'Unable to add group. Please try again.',
                  }),
                  groupAction.updateLoader({ isLoading: false })
                );
              })
            )
          )
        )
      )
    );
  });

  update$ = createEffect(() => {
    return this.action$.pipe(
      ofType(groupAction.update),
      exhaustMap(({ data, id, message, ref }) =>
        this.service.update(id, data).pipe(
          exhaustMap(department => {
            ref && this.matDialogRefStore.close(ref);

            this.toastrService.success(
              message ?? 'Group updated successfully.'
            );
            return of(
              groupAction.updateSuccess({
                data: { changes: department, id },
              }),
              groupAction.updateLoader({ isLoading: false })
            );
          }),
          catchError(() => {
            return of(
              setErrorToast({
                message: 'Unable to update department try Again',
              }),
              groupAction.updateLoader({ isLoading: false })
            );
          })
        )
      )
    );
  });
  delete$ = createEffect(() => {
    return this.action$.pipe(
      ofType(groupAction.delete),
      exhaustMap(({ id }) => {
        return this.service.delete(id).pipe(
          switchMap(() => {
            this.toastrService.success('Group deleted successfully.');
            return of(
              groupAction.deleteSuccess({ id }),
              groupAction.fetchDissociateUser()
            );
          }),
          catchError(() =>
            of(
              setErrorToast({
                message: 'Unable to delete group.',
              })
            )
          )
        );
      })
    );
  });

  fetchDissociateUser$ = createEffect(() => {
    return this.action$.pipe(
      ofType(groupAction.fetchDissociateUser),
      withLatestFrom(this.store.select(selectOrganization)),
      exhaustMap(([_, organization]) =>
        this.service.dissociateMember(organization.id).pipe(
          switchMap(members =>
            of(groupAction.fetchDissociateUserSuccess({ data: members }))
          ),
          catchError(() =>
            of(
              setErrorToast({
                message: 'Unable to fetch Group member try again.',
              })
            )
          )
        )
      )
    );
  });
  addMember$ = createEffect(() => {
    return this.action$.pipe(
      ofType(groupAction.addMember),
      exhaustMap(({ data }) =>
        this.service.addMember([data]).pipe(
          switchMap(() => {
            this.toastrService.success('Member added successfully.');
            return of(
              groupAction.fetchDissociateUser(),
              groupAction.fetchGroup()
            );
          }),
          catchError(() =>
            of(
              setErrorToast({
                message: 'Unable to add member try again.',
              })
            )
          )
        )
      )
    );
  });

  deleteMember$ = createEffect(() => {
    return this.action$.pipe(
      ofType(groupAction.deleteMember),
      exhaustMap(({ id }) =>
        this.service.deleteMember(id).pipe(
          switchMap(() => {
            this.toastrService.success('Member remove successfully.');
            return of(
              groupAction.fetchDissociateUser(),
              groupAction.fetchGroup()
            );
          }),
          catchError(() =>
            of(setErrorToast({ message: 'Unable to remove member try again.' }))
          )
        )
      )
    );
  });
}

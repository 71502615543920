import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { MainComponent } from '@layout/main/main.component';
import { LoadingComponent } from '@component/loading/loading.component';
import { BaseComponent } from '@utils/BaseComponent';
import { selectRouterData } from '@stores/router/router.selector';
import { initialize } from '@stores/auth/auth.actions';
import { IconComponent } from '@shared/component/icon/icon.component';
import { IconResolver, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SVG_ICON } from '@constants';
import { environment } from '@environments';
import { Title } from '@angular/platform-browser';
import { AuthComponent } from '@layout/auth/auth.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    MainComponent,
    AuthComponent,
    LoadingComponent,
    IconComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent extends BaseComponent implements OnInit {
  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private titleService: Title
  ) {
    super();
    this.titleService.setTitle(this.title);

    Object.entries(SVG_ICON).forEach(([name, svg]) => {
      iconRegistry.addSvgIconLiteral(
        name,
        sanitizer.bypassSecurityTrustHtml(svg)
      );
    });
    const resolver: IconResolver = name =>
      sanitizer.bypassSecurityTrustResourceUrl(`assets/icon/${name}.svg`);
    iconRegistry.addSvgIconResolver(resolver);
  }
  title = environment.title || 'Dashboard';
  showLayout: boolean;
  layout?: 'main' | 'auth';
  ngOnInit(): void {
    this.store.dispatch(initialize());
    this.subscriptions$.add(
      this.store.select(selectRouterData).subscribe({
        next: ({ showLayout = true, layout }) => {
          this.showLayout = showLayout;
          this.layout = layout;
        },
      })
    );
  }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { OrganizationService, MatDialogRefStore } from '@services';
import { ToastrService } from 'ngx-toastr';
import {
  addOrganization,
  addOrganizationSuccess,
  cleanOrganization,
  handleFailed,
  loadOrganization,
  loadOrganizationSuccess,
  updateLoading,
  updateOrganization,
  updateOrganizationSuccess,
} from './organization.action';
import { EMPTY, catchError, exhaustMap, map, of, take } from 'rxjs';
import { setErrorToast } from '@stores/shared/shared.actions';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectSetting } from '@stores/auth/auth.selector';
import { updateAuth } from '@stores/auth/auth.actions';

// effect of a organization state
@Injectable()
export class OrganizationEffects {
  constructor(
    private actions$: Actions,
    private matDialogRefStore: MatDialogRefStore,
    private organizationService: OrganizationService,
    private toastrService: ToastrService,
    private router: Router,
    private store: Store
  ) {}
  loadOrganization$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadOrganization),
      exhaustMap(() =>
        this.organizationService.get().pipe(
          exhaustMap(({ data }) => of(loadOrganizationSuccess({ data }))),
          catchError(() => {
            return of(
              setErrorToast({ message: 'Error loading organization.' })
            );
          })
        )
      )
    );
  });

  addOrganization$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(addOrganization),
      exhaustMap(({ data, ref }) => {
        return this.organizationService.add(data).pipe(
          exhaustMap(organization => {
            ref && this.matDialogRefStore.close(ref);
            this.toastrService.success('Organization added successfully.');
            return of(addOrganizationSuccess({ data: organization }));
          }),
          catchError(e => {
            ref && this.matDialogRefStore.close(ref);
            return of(
              handleFailed({
                errorProps: {
                  message: e?.message ?? 'Unable to add organization.',
                },
              })
            );
          })
        );
      })
    );
  });
  updateOrganization$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateOrganization),
      exhaustMap(({ data, id, message, ref }) =>
        this.organizationService.update(data, id).pipe(
          exhaustMap(organization => {
            ref && this.matDialogRefStore.close(ref);
            this.toastrService.success(
              message ?? 'Organization edited successfully.'
            );

            return this.store.select(selectSetting).pipe(
              take(1),
              exhaustMap(auth => {
                if (auth.organization.id === organization.id) {
                  return of(
                    updateOrganizationSuccess({
                      data: { changes: { ...organization }, id },
                    }),
                    updateAuth({ data: { ...auth, organization } })
                  );
                }
                return of(
                  updateOrganizationSuccess({
                    data: { changes: { ...organization }, id },
                  })
                );
              })
            );
          }),
          catchError(() => {
            return of(
              handleFailed({
                errorProps: {
                  message: 'Unable to complete operation.',
                },
              })
            );
          })
        )
      )
    );
  });

  handleFailed$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(handleFailed),
      exhaustMap(({ errorProps }) => of(setErrorToast(errorProps)))
    );
  });

  setLoading$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateOrganization, addOrganization),
      exhaustMap(() => of(updateLoading({ isLoading: true })))
    );
  });

  clean$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(cleanOrganization),
        exhaustMap(({ id }) =>
          this.organizationService.clear(id).pipe(
            map(() =>
              this.toastrService.success(
                'Organization clear successfully.',
                'Organization'
              )
            ),
            catchError(() => {
              this.toastrService.error(
                'Internal server Error try again!!.',
                'Organization'
              );
              return EMPTY;
            })
          )
        )
      );
    },
    { dispatch: false }
  );
}

import type {
  HttpErrorResponse,
  HttpInterceptorFn,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments';
import { Store } from '@ngrx/store';
import { selectToken } from '@stores/auth/auth.selector';
import { setLoadingCounter } from '@stores/shared/shared.actions';
import {
  EMPTY,
  catchError,
  exhaustMap,
  finalize,
  take,
  throwError,
} from 'rxjs';
function changeStatus(inc: boolean, store: Store) {
  inc
    ? store.dispatch(setLoadingCounter({ value: 1 }))
    : store.dispatch(setLoadingCounter({ value: -1 }));
}
function handleError(e: HttpErrorResponse, _router: Router, _store: Store) {
  switch (e.status) {
    case 401:
      // router.navigateByUrl('/unAuthorize');
      return throwError(() => e);
      break;
    default:
      return throwError(() => e);
  }
  return EMPTY;
}
export const customInterceptor: HttpInterceptorFn = (req, next) => {
  const store = inject(Store);
  const router = inject(Router);
  changeStatus(true, store);
  return store.select(selectToken).pipe(
    take(1),
    exhaustMap(token => {
      let headers = {};
      if (token) {
        headers = {
          Authorization: token,
        };
      }
      const modifiedReq = req.clone({
        withCredentials: true,
        url: req.url.startsWith('assets')
          ? req.url
          : environment.apiBaseUrl +
            environment.port +
            environment.version +
            req.url,
        setHeaders: headers,
      });
      return next(modifiedReq).pipe(
        catchError(e => handleError(e, router, store)),
        finalize(() => changeStatus(false, store))
      );
    })
  );
};

import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { RouterState } from '@models';
import { Store } from '@ngrx/store';
import { selectSetting } from '@stores/auth/auth.selector';
import { exhaustMap, filter, of, takeWhile } from 'rxjs';
const impersonateRoute = ['changePassword'];
export const authGuard: CanActivateFn = (route, _state) => {
  try {
    const store = inject(Store);
    const router = inject(Router);

    return store.select(selectSetting).pipe(
      takeWhile(({ isSettingFetched }) => !isSettingFetched, true),
      filter(({ isSettingFetched }) => isSettingFetched),
      exhaustMap(authState => {
        if (authState.isSettingFetched) {
          const { token, user, setting, organization } = authState;
          if (!token) {
            router.navigate(['/login']);
            return of(false);
          }

          const { allowedRole = [], id } = route.data as RouterState['data'];
          if (user?.role.name === 'superowner') {
            if (
              Object.keys(organization)?.length &&
              !(setting.navBar[id!] || impersonateRoute.includes(id!))
            ) {
              router.navigate(['/unAuthorize'], { skipLocationChange: true });
              return of(false);
            }
          }
          if (user?.role.name === 'admin') {
            if (
              Object.keys(organization)?.length &&
              !(setting.navBarAdmin[id!] || impersonateRoute.includes(id!))
            ) {
              router.navigate(['/unAuthorize'], { skipLocationChange: true });
              return of(false);
            }
          }
          if (user?.role.name === 'manager') {
            if (
              Object.keys(organization)?.length &&
              !(setting.navBarManager[id!] || impersonateRoute.includes(id!))
            ) {
              router.navigate(['/unAuthorize'], { skipLocationChange: true });
              return of(false);
            }
          }
          if (user?.role.name === 'support') {
            if (
              Object.keys(organization)?.length &&
              !(setting.navBarSupport[id!] || impersonateRoute.includes(id!))
            ) {
              router.navigate(['/unAuthorize'], {
                skipLocationChange: true,
              });
              return of(false);
            }
          }
          if (user?.role.name === 'service') {
            if (
              Object.keys(organization)?.length &&
              !(setting.navBarService[id!] || impersonateRoute.includes(id!))
            ) {
              router.navigate(['/unAuthorize'], {
                skipLocationChange: true,
              });
              return of(false);
            }
          }
          if (user?.role.name === 'marketing') {
            if (
              Object.keys(organization)?.length &&
              !(setting.navBarMarketing[id!] || impersonateRoute.includes(id!))
            ) {
              router.navigate(['/unAuthorize'], {
                skipLocationChange: true,
              });
              return of(false);
            }
          }
          if (user?.role.name === 'supervisor') {
            if (
              Object.keys(organization)?.length &&
              !(setting.navBarSupervisor[id!] || impersonateRoute.includes(id!))
            ) {
              router.navigate(['/unAuthorize'], {
                skipLocationChange: true,
              });
              return of(false);
            }
          }
          if (
            allowedRole.length === 0 ||
            allowedRole.includes(user!.role.name)
          ) {
            return of(true);
          }
          router.navigate(['/unAuthorize']);
          return of(false);
        }
        return of(false);
      })
    );
  } catch (error) {
    return false;
  }
};

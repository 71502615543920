import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ResetService } from '@services/reset.service';
import { logoutSuccess } from '@stores/auth/auth.actions';
import { setErrorToast } from '@stores/shared/shared.actions';
import { ToastrService } from 'ngx-toastr';
import { catchError, exhaustMap, of, switchMap } from 'rxjs';
import resetActions from '../reset.action';

@Injectable()
export class ResetEffect {
  private omitInitiallizeRoute = ['/forget-password', '/reset-password'];

  resetPassword$ = createEffect(() => {
    return this.action$.pipe(
      ofType(resetActions.resetPassword),
      exhaustMap(({ email }) => {
        return this.resetService.resetPassword({ email }).pipe(
          exhaustMap(() => {
            this._toast.success('Reset email sent!');
            return of(resetActions.resetEmailSuccess());
          }),
          catchError(error => {
            return of(
              setErrorToast({
                message: error.error.message,
              })
            );
          })
        );
      })
    );
  });

  setResetPassword$ = createEffect(() => {
    return this.action$.pipe(
      ofType(resetActions.setResetPassword),
      exhaustMap(({ newPassword, token }) => {
        return this.resetService.setResetPassword({ newPassword, token }).pipe(
          switchMap(() => {
            this._toast.success('Password updated!');
            return of(logoutSuccess());
          }),
          catchError(error => {
            return of(
              setErrorToast({
                message: error?.error?.message ?? 'Failed to reset password!',
              })
            );
          })
        );
      })
    );
  });

  constructor(
    private _toast: ToastrService,
    private action$: Actions,
    private store: Store,
    private resetService: ResetService,
    private router: Router
  ) {}
}

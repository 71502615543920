import { createReducer, on } from '@ngrx/store';
import { queryAdapter, queryInitialState } from './query.state';
import { queryActions } from './query.action';

export const queryReducer = createReducer(
  queryInitialState,
  on(queryActions.fetchQuerySuccess, (state, { data }) =>
    queryAdapter.setAll(data.data, { ...state, total: data.count })
  ),
  on(queryActions.updateFilterSuccess, function (state, { data }) {
    return {
      ...state,
      filter: data,
    };
  }),
  on(queryActions.updatePagination, function (state, { data }) {
    return { ...state, pagination: data };
  }),
  on(queryActions.fetchIntentIdsSuccess, function (state, { data }) {
    return { ...state, intentIds: data.map(v => ({ label: v, value: v })) };
  })
);

import { createReducer, on } from '@ngrx/store';
import { UserInitialState, userAdapter } from './user.state';
import { userActions } from './user.actions';

export const userReducer = createReducer(
  UserInitialState,
  on(userActions.createUserSuccess, (state, { user }) =>
    userAdapter.addOne(user, { ...state, isLoading: false })
  ),
  on(userActions.updateUserSuccess, (state, { data }) =>
    userAdapter.updateOne(data, { ...state, isLoading: false })
  ),
  on(userActions.deleteUserSuccess, (state, { id }) =>
    userAdapter.removeOne(id, state)
  ),

  on(userActions.getUsersSuccess, (state, { users }) =>
    userAdapter.setAll(users, state)
  ),
  on(userActions.handleFailed, function (state) {
    return { ...state, isLoading: false };
  }),
  on(userActions.updateLoading, function (state, { isLoading }) {
    return { ...state, isLoading };
  })
);

import { paginationConfig } from '@constants';
import { Lead, Pagination, SelectModel, LeadPageModel } from '@models';

export interface LeadState {
  data: Lead[];
  total: number;
  pagination: Pagination;
  interest: SelectModel.Option[];
  filter: LeadPageModel.filter;
}

export const leadInitialState: LeadState = {
  data: [],
  total: 0,
  interest: [],
  pagination: {
    offset: paginationConfig.defaultSize,
    page: 1,
  },
  filter: {},
};

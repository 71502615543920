import { createReducer, on } from '@ngrx/store';
import { likeDislikeInitialState } from './likeDislike.state';
import likeDislikeActions from './likeDislike.action';

export const likeDislikeReducer = createReducer(
  likeDislikeInitialState,
  on(likeDislikeActions.updateFilter, function (state, { data: filter }) {
    return { ...state, filter };
  }),
  on(
    likeDislikeActions.updatePagination,
    function (state, { data: pagination }) {
      return { ...state, pagination };
    }
  ),
  on(
    likeDislikeActions.fetchLikeDislikeQuerySuccess,
    function (state, { data: { count, data } }) {
      return { ...state, data, total: count };
    }
  )
);

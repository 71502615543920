import { paginationConfig } from '@constants';
import { Feedback, Pagination, FeedbackPageModel } from '@models';

export interface FeedbackState {
  data: Feedback[];
  total: number;
  pagination: Pagination;
  filter: FeedbackPageModel.filter;
}

export const feedbackInitialState: FeedbackState = {
  data: [],
  total: 0,
  pagination: {
    offset: paginationConfig.defaultSize,
    page: 1,
  },
  filter: {
    order: {
      direction: 'desc',
      field: 'createdDate',
    },
  },
};

import { createReducer, on } from '@ngrx/store';
import { dashboardInitialState } from './dashboard.state';
import { dashboardActions } from './dashboard.action';
import { produce } from 'immer';

export const dashboardReducer = createReducer(
  dashboardInitialState,
  on(dashboardActions.queryCountSuccess, (state, { data }) => {
    return produce(state, draft => {
      draft.queryCount = data;
    });
  }),
  on(dashboardActions.visitorCountSuccess, (state, { data }) => {
    return produce(state, draft => {
      draft.visitorCount = data;
    });
  }),
  on(dashboardActions.leadsCountSuccess, (state, { data }) => {
    return produce(state, draft => {
      draft.leadCount = data;
    });
  }),
  on(dashboardActions.complaintsCountSuccess, (state, { data }) => {
    return produce(state, draft => {
      draft.complainCount = data;
    });
  }),
  on(dashboardActions.botCountSuccess, (state, { data }) => {
    return produce(state, draft => {
      draft.botCount = data;
    });
  }),
  on(dashboardActions.newVisitorCountSuccess, (state, { data }) => {
    return produce(state, draft => {
      draft.newVisitorCount = data;
    });
  })
);

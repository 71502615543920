import { DashboardPageModel, SuperDashboardModel } from '@models';
export interface SuperDashboardState {
  visitorCount: DashboardPageModel.VisitorCountResponse;
  queryCount: DashboardPageModel.QueryCountResponse;
  queryBySources: SuperDashboardModel.QueryBySourceResponse[];
  qeryByStatus: SuperDashboardModel.QueryByStatusResponse[];
  querySources: SuperDashboardModel.AllQuerySourcesResponse[];
}
export const dashboardInitialState: SuperDashboardState = {
  visitorCount: {} as DashboardPageModel.VisitorCountResponse,
  queryCount: {} as DashboardPageModel.QueryCountResponse,
  queryBySources: [],
  qeryByStatus: [],
  querySources: [],
};

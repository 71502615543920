import { environment } from '@environments';
import { Role, Source } from '@models';
interface User {
  role: {
    name: Role;
    created: string;
    modified: string;
    id: string;
  };
  fullName: string;

  email: string;
}
interface Organization {
  name: string;
  location: string;
  chat_script_scheduler: boolean;
  availability: boolean;
  createdDate: string;
  rasa_url: string;
  logo?: string;
  web_link: string;
  googleCalendar: {
    url: string;
    apiId: string;
  };
  whatsApp?: {
    token: string;
    phone: string;
  };
  viber?: {
    token: string;
  };
  facebook?: {
    token: string;
    pageId: string;
    greet: string;
  };
  googleAnalyticsId: string;
  schedulerDetails: {
    mailIds: string;
  };
  id: string;
}
interface OfficeTime {
  startTime?: string | null;
  endTime?: string | null;
}
interface DaysOfficeTime {
  sunday: OfficeTime;
  monday: OfficeTime;
  tuesday: OfficeTime;
  wednesday: OfficeTime;
  thursday: OfficeTime;
  friday: OfficeTime;
  saturday: OfficeTime;
}
interface Setting {
  baseUrl: string;
  baseUrlWithoutVersion: string;
  officeTime: DaysOfficeTime;
  is_daily_scheduler: boolean;
  is_weekly_scheduler: boolean;
  organizationId: string;
  panel: {
    totalVisitor: boolean;
    totalQuery: boolean;
    totalHandledQuery: boolean;
    totalUnhandledQuery: boolean;
    totalBotEngageTime: boolean;
    totalNewVisitor: boolean;
  };
  fields: {
    lead: Record<string, string>;
    complain: Record<string, string>;
    feedback: Record<string, string>;
    appointment: Record<string, string>;
    offer: Record<string, string>;
    apiLog: Record<string, string>;
  };
  navBar: Record<string, boolean>;
  navBarAdmin: Record<string, boolean>;
  navBarManager: Record<string, boolean>;
  navBarSupport: Record<string, boolean>;
  navBarService: Record<string, boolean>;
  navBarMarketing: Record<string, boolean>;
  navBarSupervisor: Record<string, boolean>;
  source: Source[];
  id: string;
  header: Record<string, string[]>;
}

// export interface RemaningAttempts {
//   message: string
//   remainingAttempts: number
// }
export type Auth = {
  token: string | null;
  user: User;
  organization: Organization;
  setting: Setting;
  isSettingFetched: boolean;
  remainingAttempts?: number;
};
export const AUTH_INITIAL_STATE: Auth = {
  organization: {} as Organization,
  user: {} as User,
  token: '',
  setting: {
    baseUrl: `${environment.apiBaseUrl}${environment.port}${environment.version}`,
    baseUrlWithoutVersion: `${environment.apiBaseUrl}${environment.port}`,
    source: [] as Source[],
  } as Setting,
  isSettingFetched: false,
  remainingAttempts: undefined,
};
